import Coupon from "./Coupon";
import Event from "./Event";
import Payment from "./Payment";
import Ticket from "./Ticket";
import users from "../../icons/users.svg";
import orders from "../../icons/orders.svg";
import sales from "../../icons/sales.svg";
import { useEffect, useState } from "react";
import { api, auth } from "services";
import { useNavigate } from "react-router-dom";
import Spinner from "components/shared/Spinner";
import { currencyFormatter } from "utils";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const { t } = useTranslation("dashboard");

  //method to get the user role and based role it will navigate
  const fetchUserDetails = async () => {
    try {
      const res = await auth.fetchUser();
      if (!res) {
        navigate("/login");
      }
      const role = await api.getCurrentUserRoles();
      if (role === "user") {
        navigate("/");
      }
    } catch (error) {
      console.log("error ", error);
    } finally {
      setLoading(false);
    }
  };

  //method to fetch the all bookings based on organizer Id
  const fetchTotalBookings = async () => {
    try {
      const user = auth.fetchUser();
      const userRole = await api.getCurrentUserRoles();

      if (userRole === "admin") {
        const bookings = await api.getAllBookings();
        const filterBookings = bookings.filter((booking) => booking.get("status") === "complete");
        setBookings(filterBookings);
      } else {
        const bookings = await api.getOrganizerAllBookings(user?.id);
        const filterBookings = bookings.filter((booking) => booking.get("status") === "complete");
        setBookings(filterBookings);
      }
    } catch (error) {
      console.log("fetchBookings error ", error);
    } finally {
      setLoading(false);
    }
  };

  //method to fetch the totam amount of all tickets booked based on organizer Id
  const fetchTotalAmount = async () => {
    try {
      const role = await api.getCurrentUserRoles();
      if (role === "admin") {
        let amount = 0;
        const res = await api.getEvents();
        for (let i of res) {
          let totalamount = i?.get("bookings_count")?.total_tickets_amount ?? 0;
          amount += totalamount;
        }
        setTotalAmount(amount ?? 0);
      }

      if (role === "organizer") {
        const user = auth.fetchUser();
        const res = await api.organizerEvents(user?.id);
        let amount = 0;
        for (let i of res) {
          let totalamount = i?.get("bookings_count")?.total_tickets_amount ?? 0;
          amount += totalamount;
        }
        setTotalAmount(amount ?? 0);
      }
    } catch (error) {
      console.log("fetchEvents error ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchTotalBookings();
    fetchTotalAmount();
  }, [loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <>
        <div className="">
          <div className="d-sm-flex gap-2  h-100  shadows-sm mt-4 mb-2">
            <div className="card  d-flex flex-row justify-content-between p-3 shadow-sm cursor rounded-3 mb-2 mb-sm-0">
              <div>
                <p>{t('index.totalBookings')}</p>
                <p className="fw-bold d-block fs-5">{bookings?.length}</p>
              </div>
              <div className="d-flex flex-column justify-content-center px-2">
                <img src={orders} alt="" />
              </div>
            </div>

            <div className="card  d-flex flex-row justify-content-between p-3 shadow-sm cursor rounded-3 ">
              <div>
                <p>{t('index.totalAmount')}</p>
                <p className="fw-bold d-block fs-5">
                  {currencyFormatter(totalAmount)}
                </p>
              </div>
              <div className="d-flex flex-column justify-content-center px-2">
                <img src={sales} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="d-lg-flex gap-2">
            <Event />
            <Coupon />
          </div>
          <Payment />
        </div>
      </>
    </div>
  );
};

export default Dashboard;