import Spinner from "components/shared/Spinner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "services";
import { currencyFormatter } from "utils";

const Coupon = () => {
  const [coupon, setCoupon] = useState([]);
  const [data, setData] = useState([]);
  const [currentpagenumber, setCurentPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [couponType, setCouponType] = useState("true");
  const {t} = useTranslation("dashboard");
  const step_number = 5;

  const filterCoupons = (Coupons) => {
    return Coupons?.filter((item) => {
      // Correctly compare couponType as boolean when filtering active/inactive
      const isActive = couponType === "true"; // Convert couponType to boolean
      return item?.get("isActive") === isActive;
    });
  };

  const fetchCoupon = async () => {
    try {
      const userRole = await api.getCurrentUserRoles();
      if (userRole === "admin") {
        const couponData = await api.getCoupon();
        setCoupon(couponData);
        let newData = couponData?.slice(0, step_number);
        setData(newData);
      }else{
        const couponData = await api.getOrganizerCoupons();
        setCoupon(couponData);
        let newData = couponData?.slice(0, step_number);
        setData(newData);
      }
    } catch (error) {
      console.log("fetchCoupon error ", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrev = () => {
    if (currentpagenumber === 1) return;
    setCurentPageNumber((prev) => prev - 1);
  };

  const handleNext = () => {
    let finalPagenumber = Math.ceil(coupon.length / step_number);
    if (currentpagenumber === finalPagenumber) return;
    setCurentPageNumber((prev) => prev + 1);
  };

  useEffect(() => {
    fetchCoupon();
  }, []);

  // Update filtered coupons when couponType changes
  useEffect(() => {
    const filteredCoupons = filterCoupons(coupon);
    const start = (currentpagenumber - 1) * step_number;
    const end = currentpagenumber * step_number;
    setData(filteredCoupons?.slice(start, end));
  }, [couponType, currentpagenumber, coupon]);

  const handlePageNumber = (value) => {
    let isValidNumber = Math.ceil(coupon.length / step_number);
    if (value <= isValidNumber && value >= 1) {
      setCurentPageNumber(value);
    }
  };

  const validatenumber = (value) => {
    return value ?? 0;
  };

  if (loading) return <Spinner />;

  return (
    <div className="bg-white p-3 border rounded-3 shadow-sm w-100">
      <div className="d-flex justify-content-between">
        <h3 className="mx-2">{t("coupon.title")}</h3>
        <select
          className="form-select form-select-sm ms-3"
          style={{ width: "100px" }}
          aria-label="Filter by coupon type"
          value={couponType}
          onChange={(e) => setCouponType(e.target.value)}
        >
          <option value="true">{t("coupon.status.active")}</option>
          <option value="false">{t("coupon.status.inActive")}</option>
        </select>
      </div>
      <div className="table-responsive rounded-3 mt-4 mb-3">
        <table className="table">
          <thead>
            <tr>
              <th className="px-3" scope="col">
                #
              </th>
              <th className="px-3" scope="col">
                {t("coupon.couponCode")}
              </th>
              <th className="px-3" scope="col">
                {t("coupon.amount")}
              </th>
              <th className="px-3" scope="col">
                {t("coupon.quantity")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data?.map((item, index) => (
                <tr key={index}>
                  <th className="px-3" scope="row">
                    {(currentpagenumber - 1) * step_number + index + 1}
                  </th>
                  <td className="col px-3">
                    <p
                      className={
                        item?.get("isActive") ? "text-success" : "text-danger"
                      }
                    >
                      {item?.get("code")}
                    </p>
                  </td>
                  <td className="col px-3">
                    {currencyFormatter(item?.get("amount"))}
                  </td>
                  <td className="col px-3">
                    {item?.get("eventId")?.get("coupons_count")?.[
                      item?.get("code")
                    ] ?? 0}
                    /
                    {item?.get("quantity") +
                      validatenumber(
                        item?.get("eventId")?.get("coupons_count")?.[
                          item?.get("code")
                        ]
                      )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  {t("coupon.nocoupons")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className="d-flex justify-content-end">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <a className="page-link" onClick={handlePrev}>
                  Prev
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" onClick={handleNext}>
                  Next
                </a>
              </li>
            </ul>
          </nav>
        </div> */}
      </div>
    </div>
  );
};

export default Coupon;
