import { useTranslation } from "react-i18next";

export const ValidateCoupons = () => {
    const { t } = useTranslation("addcoupon");

    //method to vailidate the required input fields on entering input field
    const ValidateField = (fieldName, value) => {
        switch (fieldName) {
            case "code":
                return validateCouponCode(value);
            case "amount":
                return validateCouponCost(value);
            case "type":
                return validateCouponType(value);
            case "quantity":
                return validateCouponQunatity(value);
            case "isActive":
                return vaidateCouponStatus(value);

            default:
                return "";
        }
    };

    //validate Coupon Code
    const validateCouponCode = (value) => {
        if (!value?.trim()) {
            return t("validations.code");
        } else {
            return "";
        }
    };

    //validate Coupon Quantity
    const validateCouponQunatity = (value) => {
        if (!value?.toString()?.trim()) {
            return t("validations.quantity");
        } else {
            return "";
        }
    };

    //validate Coupon Cost
    const validateCouponCost = (value) => {
        if (!value?.toString()?.trim()) {
            return t("validations.cost")
        } else {
            return "";
        }
    };

    //validate Coupon Type
    const validateCouponType = (value) => {
        if (!value?.toString()?.trim()) {
            return t("validations.type")
        } else {
            return "";
        }
    };

    //validate Coupon Status
    const vaidateCouponStatus = (value) => {
        if (!value?.trim()) {
            return "Select the Coupon Status";
        } else {
            return "";
        }
    };

    //method for Coupon form Validation
    const validateCouponForm = (formData) => {
        const newErrors = {
            code: validateCouponCode(formData?.code),
            amount: validateCouponCost(formData?.amount),
            quantity: validateCouponQunatity(formData?.quantity),
            type: validateCouponType(formData?.type),
            // isActive:vaidateCouponStatus(formData?.isActive)
        };
        if (
            newErrors?.code ||
            newErrors?.amount ||
            newErrors?.quantity ||
            newErrors?.type
        ) {
            return {
                err: newErrors,
            };
        } else {
            return { err: "" };
        }
    };

    return { ValidateField, validateCouponForm }
}