import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, auth } from "services";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Profile from "./Profile";
import EditProfile from "./EditProfile";
import Password from "./Password";
import Bank from "./Bank";
import "react-datepicker/dist/react-datepicker.css";

const Index = () => {
  const [activeTab, setActiveTab] = useState("My account");
  const [edit, setEdit] = useState(false);
  const [role, setRole] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState("");
  const { t } = useTranslation("profile");

  const navigate = useNavigate();
  const childref = useRef();

  //method to get the current User Role
  const getRole = async () => {
    const role = await api.getCurrentUserRoles();
    setRole(role);
  };

  //initially when page loads it will fetch the current looged In User Role
  useEffect(() => {
    getRole();
  }, []);

  //method to delete account
  const handleDeleteAccount = async () => {
    if (deleteConfirm === "delete account") {
      try {
        const res = await auth.deleteAccount();
        setDeleteConfirm("");
        navigate("/");
      } catch (error) {
        console.log("delete account error ", error);
      }
      setDeleteConfirm("");
    }
  };

  //handle goBack
  const handleGoBack = () => {
    setEdit(false);
    setActiveTab("My account");
  };

  //handle Active Tab
  const handleActivetab = (tabname) => {
    setActiveTab(tabname);
    setEdit(false);
  };

  //calling child component method from parent component
  const handleProfileSave = () => {
    handleActivetab("My account");
    if (childref?.current) {
      childref?.current?.callChildFunction();
    }
  };

  //calling child component method from parent component
  const handlePasswordSave = () => {
    if (childref?.current) {
      childref?.current?.callChildFunction();
    }
  };

  //rendercomponent based on condition
  const renderPage = () => {
    // console.log("activeTab ",activeTab)
    switch (activeTab) {
      case "My account":
        return <Profile />;
      case "Edit account":
        return <EditProfile ref={childref} />;
      case "Change Password":
        return <Password ref={childref} />;
      case "Bank":
        return <Bank />;
    }
  };

  return (
    <div className="container py-3  my-3">
      <div className="row">
        <div className="col-md-3 mb-md-0 mb-3">
          <aside>
            <div>
              <ul
                class="nav nav-pills list-unstyled fw-bold d-flex flex-column gap-2 "
                role="tablist"
              >
                <li
                  class="nav-item"
                  role="presentation"
                  onClick={() => {
                    setEdit(() => false);
                    setActiveTab("My account");
                  }}
                >
                  <a
                    class="nav-link active cursor-pointer"
                    id="nav-upcoming"
                    data-bs-toggle="tab"
                    data-bs-target="#upcoming"
                    type="button"
                    role="tab"
                    aria-controls="nav-upcoming"
                    aria-selected="true"
                  >
                    <i class="bi bi-person-fill mx-2"></i>
                    {t("myAccount.title")}
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link cursor-pointer d-flex align-items-center"
                    id="nav-failed"
                    data-bs-toggle="tab"
                    data-bs-target="#failed"
                    type="button"
                    role="tab"
                    onClick={() => handleActivetab("Change Password")}
                  >
                    {/* <div className="d-flex justify-content-start"> */}
                    <FontAwesomeIcon icon={faKey} className="mx-2" />
                    <p className="text-truncate w-100 m-0">
                      {t("changePassword.title")}
                    </p>
                    {/* </div> */}
                  </a>
                </li>
                {role === "organizer" && (
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link  cursor-pointer d-flex align-items-center"
                      id="nav-failed"
                      data-bs-toggle="tab"
                      data-bs-target="#failed"
                      type="button"
                      role="tab"
                      onClick={() => handleActivetab("Bank")}
                      // onClick={() => setActiveTab("Bank")}
                    >
                      {/* <div className="d-flex justify-content-start"> */}
                      <i class="bi bi-bank2 mx-2"></i>
                      <p className="text-truncate w-100 m-0 ">Bank Account</p>
                      {/* </div> */}
                    </a>
                  </li>
                )}
                <li class="nav-item " role="presentation">
                  <a
                    id="nav-failed"
                    className="mav-link cursor-pointer d-flex align-items-center btn btn-outline-danger"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    {/* <div className="d-flex "> */}
                    <i className="bi bi-person-x-fill mx-2"></i>
                    <p className="text-truncate fw-bold m-0">
                      {t("changePassword.deleteAccount")}
                    </p>
                    {/* </div> */}
                  </a>
                </li>
              </ul>
            </div>
          </aside>
        </div>
        <div className="col-md-9 px-3 px-md-5 px-lg-3">
          <div className="d-flex justify-content-between fw-bold">
            {edit && (
              <div className="d-inline cursor d-flex" onClick={handleGoBack}>
                <i class="bi bi-arrow-left-circle mx-2 fs-3"></i>
                <h2 className="fw-bold">Edit Account</h2>
              </div>
            )}
            {activeTab == "My account" && (
              <h2 className="fw-bold">{`${t("myAccount.title")}`}</h2>
            )}
            {activeTab == "Change Password" && (
              <div className="d-flex justify-content-between w-100">
                <h3 className="fw-bold"> {`${t("changePassword.title")}`}</h3>
                <button
                  className="btn btn-secondary fw-bold"
                  onClick={handlePasswordSave}
                >
                  Save
                </button>
              </div>
            )}
            <div>
              {(activeTab === "My account" || activeTab === "Edit account") && (
                <>
                  {edit ? (
                    <button
                      className="btn btn-secondary fw-bold"
                      onClick={handleProfileSave}
                    >
                      {t("save")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-secondary fw-bold"
                      onClick={() => {
                        setEdit(true);
                        setActiveTab("Edit account");
                      }}
                    >
                      {t("edit")}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div>{renderPage()}</div>
        </div>
      </div>
      {/* Model */}
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 fw-bold" id="staticBackdropLabel">
                {t("deleteAccount.title")}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body d-flex">
              <div>
                <p>{t("deleteAccount.description")}</p>

                <input
                  className="d-block border-2 px-2"
                  type="text"
                  name="deleteConfirm"
                  value={deleteConfirm}
                  onChange={(e) => setDeleteConfirm(e.target.value)}
                  placeholder="delete account"
                />
                <br />
                <p className="fw-normal m-0">{t("deleteAccount.note")}</p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setDeleteConfirm("")}
              >
                {t("deleteAccount.cancel")}
              </button>

              <button
                onClick={handleDeleteAccount}
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                disabled={deleteConfirm !== "delete account"}
              >
                {t("deleteAccount.delete")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
