import Spinner from "components/shared/Spinner";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { api } from "services";
import { BankForm, ValidateBankFields } from "views/validation/Bank";

const BankEdit = forwardRef((props, ref) => {
  const { isEdit } = props;
  const [account, setAccount] = useState({});
  const [errors, setErrors] = useState({});
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);

  //method to fetch the bank details based on current logged In organizer Id
  const fetchBankDetails = async () => {
    try {
      const res = await api.getBankDetails();
      setId(res?.id);
      setAccount(res?.toJSON());
    } catch (error) {
      console.log("fetchBankDetails ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchBankDetails();
    }
  }, []);

  //method to store the bank details in state
  const handleUpdateAccount = (e) => {
    const { name, value } = e.target;
    const err = ValidateBankFields(name, value);
    if (name == "accountNumber" || name == "confirmAccountNumber") {
      if (/^\d{1,17}(?:,\d{3}){0,4}?$|^$/.test(value)) {
        setAccount((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        if (name == "confirmAccountNumber" || name == "accountNumber") {
          if (name === "confirmAccountNumber") {
            if (account?.accountNumber != value) {
              setErrors((prev) => ({
                ...prev,
                [name]: "Account doesn't matched",
              }));
            } else {
              setErrors((prev) => ({
                ...prev,
                [name]: err,
              }));
            }
            if (account?.accountNumber == value && value !== "") {
              setErrors((prev) => ({
                ...prev,
                ["accountNumber"]: "",
                ["confirmAccountNumber"]: "",
              }));
            }
          }
          if (name === "accountNumber") {
            if (account?.confirmAccountNumber != value && value !== "") {
              setErrors((prev) => ({
                ...prev,
                [name]: "Account doesn't matched",
              }));
            } else {
              setErrors((prev) => ({
                ...prev,
                [name]: err,
              }));
            }
          }
          if (account?.confirmAccountNumber == value) {
            setErrors((prev) => ({
              ...prev,
              ["accountNumber"]: "",
              ["confirmAccountNumber"]: "",
            }));
          }
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: err,
          }));
        }
      }
    } else {
      setAccount((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: err,
      }));
    }
  };

  //method to submit the bank details
  const handleSubmit = async (e) => {
    if (account?.accountNumber == account?.confirmAccountNumber) {
      const { err } = BankForm(account);
      if (!err) {
        try {
          let updatedAccount = account;
          updatedAccount.accountNumber = Number(account?.accountNumber);
          updatedAccount.confirmAccountNumber = Number(
            account?.confirmAccountNumber
          );
          setLoading(true)
          const res = await api.addBankDetails(updatedAccount);
          if (res) {
            setAccount({});
          }
        } catch (error) {
          console.log("handleSubmit Error ", error);
        } finally{
          setLoading(false);
        }
      } else {
        setErrors(err);
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        ["confirmAccountNumber"]: "Account doesn't matched",
      }));
    }
  };

  const handleUpdate = async (e) => {
    if (account?.accountNumber == account?.confirmAccountNumber) {
      const { err } = BankForm(account);
      if (!err) {
        try {
          let updatedAccount = account;
          updatedAccount.accountNumber = Number(account?.accountNumber);
          updatedAccount.confirmAccountNumber = Number(
            account?.confirmAccountNumber
          );
          setLoading(true);
          const res = await api.updateBankDetails(updatedAccount, id);
          if (res) {
            setAccount({});
          }
          // window.location.reload();
        } catch (error) {
          console.log("handleSubmit Error ", error);
        } finally {
          setLoading(false);
        }
      } else {
        setErrors(err);
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        ["confirmAccountNumber"]: "Account doesn't matched",
      }));
    }
  };

  //this method is used to call the function in child fom parent
  useImperativeHandle(ref, () => ({
    handleSubmit,
    handleUpdate,
  }));

  if (loading)
    return (
      <div className="d-flex justify-content-center align-content-center">
        <Spinner />
      </div>
    );

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 mb-2">
          <label htmlFor="" className="fw-bold my-2">
            Account Name
          </label>
          <div>
            <input
              type="text"
              name="accountName"
              value={account?.accountName}
              onChange={handleUpdateAccount}
              className="form-control"
              required
            />
          </div>
          <p className="text-danger">{errors && errors?.accountName}</p>
        </div>

        <div className="col-md-6 col-12 mb-2">
          <label htmlFor="" className="fw-bold my-2">
            Account Number
          </label>
          <input
           type="text"
            name="accountNumber"
            value={account?.accountNumber}
            onChange={handleUpdateAccount}
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.accountNumber}</p>
        </div>

        <div className="col-md-6 col-12 mb-2">
          <label htmlFor="" className="fw-bold my-2">
            Confirm Account Number
          </label>
          <input
            type="text"
            name="confirmAccountNumber"
            value={account?.confirmAccountNumber}
            onChange={handleUpdateAccount}
            className="form-control"
          />
          <p className="text-danger">
            {errors && errors?.confirmAccountNumber}
          </p>
        </div>

        <div className="col-md-6 col-12 mb-2">
          <label htmlFor="accountType" className="fw-bold my-2">
            Account Type
          </label>
          <select
            name="accountType"
            className="form-select"
            aria-label="Select account type"
            onChange={handleUpdateAccount}
            value={account?.accountType || ""}
          >
            <option value="" disabled selected>
              Select Account type
            </option>
            <option value="Saving">Saving</option>
            <option value="Overdraft">Overdraft</option>
            <option value="Current">Current</option>
          </select>
          <p className="text-danger">{errors && errors?.accountType}</p>
        </div>

        <div className="col-md-6 col-12 mb-2">
          <label htmlFor="bankLocation" className="fw-bold my-2">
            Bank Location
          </label>
          <select
            name="location"
            className="form-select"
            value={account?.location || ""}
            aria-label="Default select example"
            onChange={handleUpdateAccount}
          >
            <option value="">Select a country</option>
            <option value="India">India</option>
            <option value="Norway">Norway</option>
            <option value="USA">USA</option>
            <option value="China">China</option>
            <option value="Japan">Japan</option>
            <option value="Germany">Germany</option>
            <option value="United Kingdom">United Kingdom</option>
            <option value="France">France</option>
            <option value="Italy">Italy</option>
            <option value="Canada">Canada</option>
            <option value="Australia">Australia</option>
            <option value="Brazil">Brazil</option>
            <option value="Russia">Russia</option>
            <option value="South Africa">South Africa</option>
            <option value="South Korea">South Korea</option>
            <option value="Mexico">Mexico</option>
            <option value="Spain">Spain</option>
            <option value="Argentina">Argentina</option>
            <option value="Netherlands">Netherlands</option>
            <option value="Sweden">Sweden</option>
            <option value="Switzerland">Switzerland</option>
            <option value="Saudi Arabia">Saudi Arabia</option>
            <option value="Turkey">Turkey</option>
            <option value="Indonesia">Indonesia</option>
            <option value="Malaysia">Malaysia</option>
            <option value="Philippines">Philippines</option>
            <option value="Singapore">Singapore</option>
            <option value="New Zealand">New Zealand</option>
            <option value="Thailand">Thailand</option>
            <option value="Poland">Poland</option>
            <option value="Nigeria">Nigeria</option>
          </select>
          <p className="text-danger">{errors && errors?.location}</p>
        </div>

        <div className="col-12 mb-2">
          {account?.location == "India" ? (
            <label className="fw-bold my-2">IFSC Code</label>
          ) : (
            <label htmlFor="" className="fw-bold my-2">
              SWIFT Code
            </label>
          )}

          <input
            type="text"
            name="code"
            value={account?.code}
            onChange={handleUpdateAccount}
            className="form-control"
          />
        </div>
        <p className="text-danger">{errors && errors?.code}</p>
      </div>
    </div>
  );
});

export default BankEdit;