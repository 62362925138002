import "App.css";
import logo from "images/logo.svg";
import facebook from "icons/facebook.svg";
import instagram from "icons/instagram.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-footer mt-auto">
      <div className="footer py-5">
        <img src={logo} alt="logo" />
        <br />
        <p className="py-3">
          <a href="https://facebook.com/Mintixofficial/" className="px-2">
            <img src={facebook} width="3%" />
          </a>
          <a href="https://www.instagram.com/mintixofficial?igsh=cWo2OWZ2ems1ZnRw">
            <img src={instagram} width="3%" />
          </a>
        </p>
        <a href="/terms" className="text-reset small">
          {t("terms")}
        </a>
        <br />
        <br />
        <p className="small">{t("footer_description")}</p>
      </div>
    </div>
  );
};
export default Footer;
