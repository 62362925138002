import Spinner from "components/shared/Spinner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { api, stripe } from "services";

const Return = () => {
  const [status, setStatus] = useState(null);
  const { t } = useTranslation("return");
  const navigate = useNavigate();
  const [loader,setLoader] = useState(true);

  const handleTicketPage = async () => {
    setTimeout(() => {
    navigate("/tickets")
    }, 3000); // 3000 milliseconds = 3 second
  };

  const createTicketPdf = async () => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const sessionId = urlParams.get("session_id");
      const bookingId = urlParams.get("booking_id");
      console.log("queryString ",queryString);
      console.log("urlParams ",urlParams);
      console.log("sessionId ",sessionId);
      console.log("bookingId ",bookingId);

      let bookingData = JSON.parse(localStorage.getItem("booking"));
      const data = await stripe.getPaymentStatus(sessionId);
      console.log("status data ",data)
      setStatus(data?.status);
      if (data?.status === "complete") {
        //Generate & Mail Ticket after uploading it to aws s3.
       const res = await api.createTicket(bookingId);
        console.log("createTicket res ",res)
        //update the event ticket count and ticketTypes count
        const result = await api.eventTicketUpdate(bookingData);
        console.log("eventTicketUpdate ",result)
        localStorage.removeItem("booking");
        console.log("loder success")
      }
      // setStatus(data?.status);

      // stripe.getPaymentStatus(sessionId)
      // .then((data) => {
      //     setStatus(data.status);

      //     if(data.status == "complete"){
      //         //Generate & Mail Ticket after uploading it to aws s3.
      //       api.createTicket(bookingId);
      //         //Reduce no. of tickets

      //         //Reduce coupon codes.
      //     }
      // });
    } catch (error) {
      console.log("createTicket Error ", error);
    }finally{
      console.log("finally ",loader)
      setLoader(false)
    }
  };

  useEffect(() => {
    console.log("callimng createTicketPDF")
    createTicketPdf();
  }, []);

  if(loader) return <Spinner/>

  if (status == "open") {
    console.log("status open ",status)
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    console.log("status complete ",status)
    // localStorage.setItem('hasReloaded', true);
    return (
      <div id="success" class="container py-5 fs-5">
        <p>
          {t("thankYou")}{" "}
          <p onClick={handleTicketPage} className="text-reset cursor d-inline text-decoration-underline">
            <b>{t("myticket")}</b>
          </p>
        </p>

        {/* <p>{t('thankYou')} <a onClick={handleTicketPage} className="text-reset cursor"> <b>{t('myticket')}</b></a></p> */}
        {/* <p>Tusen takk for ditt kjøp! Billetten(e) er nå sendt til din e-post og er også tilgjengelige under <a onClick={handleTicketPage} className="text-reset cursor"><b>'Mine billetter'</b></a> på din konto.<br/><br/> */}
        <p>{t("contactOrganizer")}</p>
        {/* Ved spørsmål angående showet du har kjøpt billett til, kontakt arrangøren direkte. For spørsmål om vårt system er du velkommen til å kontakte oss på sosiale medier, via e-post eller telefon. <br/><br/>

                Se <a href="/about" class="text-reset"><b>'Om oss'</b></a> for mer informasjon.

                </p> */}
        <p>
          {t("see")}{" "}
          <a href="/about" class="text-reset">
            <b>{t("aboutus")}</b>
          </a>
          {t("text2")}
        </p>
        {/* <a href="">t</a> */}
      </div>
    );
  }
};

export default Return;