import { useEffect, useState } from "react";
import { api } from "services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import Spinner from "components/shared/Spinner";
import { useNavigate } from "react-router-dom";

const Tickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("mytickets");
  const navigate = useNavigate();

  async function checkRolesAccess() {
    try {
      const role = await api.getCurrentUserRoles();
      if (role === "organizer" || role === "admin") {
        return navigate("/dashboard");
      }
    } catch (error) {
      console.log("error ", error);
    }
  }

  useEffect(() => {
    checkRolesAccess();
    let timeoutId;

    const fetchTickets = async () => {
      try {
        const res = await api.getBookings();
        // console.log("tickets res ", res);
        setTickets(res);
      } catch (error) {
        console.log("Error ", error);
      } finally {
        setLoading(false);

        // Check if the page has already been reloaded
        if (!localStorage.getItem("hasReloaded")) {
          localStorage.setItem("hasReloaded", "true");

          // Delay the page reload by 2 seconds (2000 milliseconds)
          timeoutId = setTimeout(() => {
            // Reload the page
            // window.location.reload();
          }, 2000);
        } else {
          // Remove the flag after reload
          localStorage.removeItem("hasReloaded");
        }
      }
    };

    fetchTickets();

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const downloadTicket = (url) => {
    window.location.href = url;
  };

  const RenderRow = ({ booking }) => {
    return (
      <tr key={booking?.id}>
        <td className="col-2">
          <i>
            {booking?.get("status") === "open"
              ? booking?.get("createdAt")?.toLocaleDateString()
              : booking?.get("eventId")?.get("date")?.toLocaleDateString()}
          </i>
        </td>
        <td className="col-2">{booking?.get("eventId")?.get("title")}</td>
        <td className="col-2">{booking?.get("amount")} NOK</td>
        <td className="col-2">
          {booking?.get("status") === "open" ? (
            <p>Payment Failed</p>
          ) : (
            <div className="d-flex">
              <a
                className="btn btn-outline-secondary mx-2 d-flex align-items-center"
                href={booking?.get("ticket_url")}
                download="very_important_report.pdf"
              >
                <FontAwesomeIcon icon={faFileDownload} className="fs-5 mx-2" />
                Download
              </a>

              <button
                type="button"
                className="btn btn-outline-secondary w-auto"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Sell Your Ticket
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h3>{t("title")}</h3>
          <hr />
          <ul className="nav nav-pills" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active border me-3 mb-2"
                id="nav-upcoming"
                data-bs-toggle="tab"
                data-bs-target="#upcoming"
                type="button"
                role="tab"
                aria-controls="nav-upcoming"
                aria-selected="true"
              >
                {t("upcommingShow")}
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link border"
                id="nav-failed"
                data-bs-toggle="tab"
                data-bs-target="#failed"
                type="button"
                role="tab"
              >
                {t("failedBooking")}
              </a>
            </li>
          </ul>
          <div className="tab-content bg-transparent">
            <div
              className="tab-pane fade show active"
              id="upcoming"
              role="tabpanel"
              tabIndex={0}
            >
              <div className="mb-5 table-responsive">
                <table className="table my-4">
                  <thead>
                    <tr>
                      <th scope="col-2">{t("upcommingShows.showData")}</th>
                      <th scope="col-2">{t("upcommingShows.showName")}</th>
                      <th scope="col-2">{t("upcommingShows.ticketAmount")}</th>
                      <th scope="col-2">{t("upcommingShows.tickets")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tickets
                      ? tickets
                          .filter(
                            (ticket) => ticket.get("status") === "complete"
                          )
                          .map((ticket) => (
                            <RenderRow key={ticket.id} booking={ticket} />
                          ))
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="failed"
              role="tabpanel"
              tabIndex={0}
            >
              <table className="table my-4">
                <thead>
                  <tr>
                    <th scope="col-2">{t("failedBookings.bookingDate")}</th>
                    <th scope="col-2">{t("failedBookings.showName")}</th>
                    <th scope="col-2">{t("failedBookings.ticketAmount")}</th>
                    <th scope="col-2">{t("failedBookings.refundStatus")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tickets
                    ? tickets
                        .filter((ticket) => ticket.get("status") === "open")
                        .map((ticket) => (
                          <RenderRow key={ticket.id} booking={ticket} />
                        ))
                    : null}
                </tbody>
              </table>
            </div>
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    <b> Ticket Reselling</b>
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <p>Ticket Reselling will be available soon.</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tickets;
