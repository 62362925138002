import { useTranslation } from "react-i18next";

export const Eventvalidation = () => {
    const { t } = useTranslation("addevent")
    const ValidateEventFields = (fieldName, value) => {
        switch (fieldName) {
            case "cover_photo":
                return validateCoverPhoto(value)
            case "email":
                return isValidEmail(value);
            case "title":
                return validateTitle(value);
            case "description":
                return validateDescription(value);
            case "address":
                return validateAddress(value);
            case "date":
                return validateDate(value);
            default:
                return "";
        }
    };

    //required field for all text input
    const validateCoverPhoto = (value) => {
        if (!value?.trim()) {
            // return "This is a required field.";
            return t("validations.coverPhoto");
        } else {
            return "";
        }
    };

    //validate MaxTickets
    const validateMaxTicket = (ticketPrice) => {
        if (ticketPrice <= 0) {
            // return "Enter Max Ticket Number !";
            return t("validations.maxTickets")
        }
    };


    //Validate Event Title
    const validateTitle = (name) => {
        if (!name?.trim()) {
            return t("validations.title")
        }
        return "";
    };

    //Validate Event Description
    const validateDescription = (description) => {
        if (!description?.trim()) {
            return t("validations.description")
        }
        return "";
    };

    //Validate Event Address
    const validateAddress = (address) => {
        if (!address?.trim()) {
            return t("validations.address")
        }
        return "";
    };

    //Validate Cost
    const validateEventCost = (cost) => {
        if (cost <= 0) {
            return "Event cost is Required !";
        }
    };

    //Validate Date
    const validateDate = (date) => {
        if (date === "") {
            return t("validations.date")
        }

        // Check if the date is an instance of Date and if it's a valid date
        if (!(date instanceof Date) || isNaN(date.getTime())) {
            return "Invalid date format";
        }
        return ""

    };

    //validate the email
    const isValidEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let isValid = regex.test(email);
        if (isValid) {
            return "";
        } else {
            return t("validations.email")
        }
    }

    //method to validate the dynamic ticket types
    const validateTicketTypes = (ticketTypes) => {
        if(ticketTypes?.length <=0 ){
            return "Please Enter Atleast One Ticket Type"
        }
        if(ticketTypes?.length > 4){
            return "Please Ticket Type shouldn't be Exceed the max 4 Types"
        }
        const res = ticketTypes?.every((item) => {
            return parseInt(item?.value) >= 0;
        })
        if (!res) {
            return t("validations.ticketPrices")
        }
        return ""
    }

    //method fro Event form validation
    const validateEventForm = (formData) => {
        const newErrors = {
            title: validateTitle(formData?.title),
            description: validateDescription(formData?.description),
            address: validateAddress(formData?.address),
            max_tickets: validateMaxTicket(formData?.max_tickets),
            date: validateDate(formData?.date),
            gate_open_time: validateDate(formData?.gate_open_time),
            gate_close_time: validateDate(formData?.gate_close_time),
            sale_start_date: validateDate(formData?.sale_start_date),
            cover_photo: validateCoverPhoto(formData?.cover_photo),
            ticket_types: validateTicketTypes(formData?.ticket_types)
        };

        if (
            newErrors?.cover_photo ||
            newErrors?.title ||
            newErrors?.description ||
            newErrors?.address ||
            newErrors?.max_tickets ||
            newErrors?.date ||
            newErrors?.gate_open_time ||
            newErrors?.gate_close_time ||
            newErrors?.sale_start_date ||
            newErrors?.ticket_types
        ) {
            return {
                err: newErrors,
            };
        } else {
            return { err: "" };
        }
    };
    return { validateEventForm, ValidateEventFields }
}