import axios from "axios";
const environment = process.env.NODE_ENV || 'development'; // Default to 'development' if not set
const SERVER_URL = environment == 'development'
? process.env.REACT_APP_SERVER_URL_DEV
: process.env.REACT_APP_SERVER_URL_PROD;

export function getPaymentStatus(sessionId){
    return fetch(`${SERVER_URL}/session-status?session_id=${sessionId}`)
            .then((res) => res.json());
}

export function createCheckout(ticketAmount, userEmail, bookingId){
    return fetch(`${SERVER_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            amount: ticketAmount,
            email: userEmail,
            bookingId: bookingId
        })
    }).then((res) => res.json())
}

export const addImagetoS3 = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await axios.post(`${SERVER_URL}/uploadImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('File uploaded successfully:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };