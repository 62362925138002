//method to vailidate the required input fields on entering input field
export const ValidateField = (fieldName, value) => {
  switch (fieldName) {
    case "email":
      return validateEmail(value);
    case "title":
      return validateTitle(value);
    case "code":
      return validateCouponCode(value);
    case "amount":
      return validateCouponCost(value);
    case "type":
      return validateCouponType(value);
    case "quantity":
      return validateCouponQunatity(value);
    case "isActive":
      return vaidateCouponStatus(value);
    case "eventType":
    case "startDate":
    case "numberOfShows":
    case "venueLocation":
    case "artist":
    case "venueName":
    case "organizationNumber":
    case "companyName":
    case "contactName":
    case "contactEmail":
    case "contactPhone":
    case "invoicePostalCode":
    case "invoicePostalArea":
    case "invoiceDeliver":
    case "postalZipCode":
    case "postalArea":
    case "officeZipCode":
    case "officeCity":
    case "user":
      return requiredField(value);
    case "username":
    case "firstname":
    case "lastname":
      return validateText(value);
    case "password":
      return validatePassword(value);
    case "confirmPassword":
      return validatePassword(value);
    default:
      return "";
  }
};

const validateText = (value) => {
  if (!value?.trim()) {
    return "This is a required field."
  } else {
    return "";
  }
}

//validate Coupon Cost
export const validateCouponCost = (value) => {
  if (!value?.toString()?.trim()) {
    return "Coupon Cost is Required";
  } else {
    return "";
  }
};
//validate Coupon Code
export const validateCouponCode = (value) => {
  if (!value?.trim()) {
    return "Coupon Code is Required";
  } else {
    return "";
  }
};

//validate Coupon Quantity
export const validateCouponQunatity = (value) => {
  if (!value?.toString()?.trim()) {
    return "Coupon Quantity is Required";
  } else {
    return "";
  }
};

//validate Coupon Type
export const validateCouponType = (value) => {
  if (!value?.toString()?.trim()) {
    return "Select the Coupon Type";
  } else {
    return "";
  }
};

//validate Coupon Status
export const vaidateCouponStatus = (value) => {
  if (!value?.trim()) {
    return "Select the Coupon Status";
  } else {
    return "";
  }
};

//Validate Password
// export const validatePassword = (name) => {
//   if (!name?.trim()) {
//     return "Please Enter the Password!";
//   } else if (name.length === 0 || name.length < 8) {
//     return "Please enter the password - At least 8 characters";
//   } else {
//     return "";
//   }
// };


export const validatePassword = (value) => {
  let passworderror = ""
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&^]{8,15}$/;
  if (!regex.test(value)) {
    passworderror = "Password must be atleast 8 characters long, include at least one uppercase letter, one lowercase letter, one digit, and one special character.";
  }
  else {
    passworderror = ""
  }

  return passworderror
};

//Validate Event Title
export const validateTitle = (name) => {
  if (!name?.trim()) {
    return "Event Title is Required!";
  }
  return "";
};

//Validate Event Description
export const validateDescription = (description) => {
  if (!description?.trim()) {
    return "Event Description is Required !";
  }
  return "";
};

//Validate Event Address
export const validateAddress = (address) => {
  if (!address?.trim()) {
    return "Event Address is Required !";
  }
  return "";
};

//Validate Cost
export const validateEventCost = (cost) => {
  if (cost <= 0) {
    return "Event cost is Required !";
  }
};

//Validate Date
export const validateDate = (date) => {
  if (date === "") {
    return "Date and Time is Required";
  }

  // Check if the date is an instance of Date and if it's a valid date
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return "Invalid date format";
  }

  return ""

};

//validate the email
export function isValidEmail(email) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let isValid = regex.test(email);
  if (isValid) {
    return "";
  } else {
    return "Please Enter Valid Email";
  }
}

//validate Email
export const validateEmail = (email) => {
  if (!email?.trim()) {
    return "Email is Required";
  } else {
    return isValidEmail(email);
  }
};

//validate MaxTickets
const validateMaxTicket = (ticketPrice) => {
  if (ticketPrice <= 0) {
    return "Enter Max Ticket Number !";
  }
};

//validate event form ticket types cost
export const validateCost = (name, cost) => {
  switch (name) {
    case "Ordinær":
      return validateEventCost(cost);
    case "Student & U18":
      return validateEventCost(cost);
    case "Ledsager":
      return validateEventCost(cost + 1);
    default:
      return "Unknown Field"
  }
};

//tono form validation common field
export const requiredField = (value) => {
  console.log("value ",value)
  if (value !== "") {
    return "This is a required field.";
  } else {
    return "";
  }
};

//validation Password form validation
export const validatePasswordForm = (formData) => {
  const newErrors = {
    newPassword: validatePassword(formData?.newPassword),
    confirmPassword: validatePassword(formData?.confirmPassword),
    oldPassword: validatePassword(formData?.oldPassword)
  };
  if (newErrors?.newPassword || newErrors?.confirmPassword) {
    return newErrors;
  } else {
    return "";
  }
};

//method fro Event form validation
export const validateForm = (formData) => {
  const newErrors = {
    title: validateTitle(formData?.title),
    description: validateDescription(formData?.description),
    address: validateAddress(formData?.address),
    max_tickets: validateMaxTicket(formData?.max_tickets),
    date: validateDate(formData?.date),
    gate_open_time: validateDate(formData?.gate_open_time),
    gate_close_time: validateDate(formData?.gate_close_time),
    sale_start_date: validateDate(formData?.sale_start_date),
    cover_photo: requiredField(formData?.cover_photo),

    // ticket_types: {
    //   Ordinær: validateCost("Ordinær", formData?.ticket_types?.Ordinær),
    //   "Student & U18": validateCost(
    //     "Student & U18",
    //     formData?.ticket_types["Student & U18"]
    //   ),
    //   Ledsager: validateCost("Ledsager", formData?.ticket_types?.Ledsager),
    // },
  };

  if (
    newErrors?.cover_photo ||
    newErrors?.title ||
    newErrors?.description ||
    newErrors?.address ||
    newErrors?.max_tickets ||
    newErrors?.date ||
    newErrors?.gate_open_time ||
    newErrors?.gate_close_time ||
    newErrors?.sale_start_date
    // newErrors?.ticket_types.Ordinær ||
    // newErrors?.ticket_types["Student & U18"] ||
    // newErrors?.ticket_types.Ledsager
  ) {
    return {
      err: newErrors,
    };
  } else {
    return { err: "" };
  }
};

//method for Profile form validation
export const validateProfileForm = (formData) => {
  const newErrors = {
    email: validateEmail(formData?.email),
    phone: requiredField(formData?.phone)
  };
  if (newErrors?.email) {
    return newErrors;
  } else {
    return "";
  }
};

//method for Coupon form Validation
export const validateCouponForm = (formData) => {
  const newErrors = {
    code: validateCouponCode(formData?.code),
    amount: validateCouponCost(formData?.amount),
    quantity: validateCouponQunatity(formData?.quantity),
    type: validateCouponType(formData?.type),
    // isActive:vaidateCouponStatus(formData?.isActive)
  };
  if (
    newErrors?.code ||
    newErrors?.amount ||
    newErrors?.quantity ||
    newErrors?.type
  ) {
    return {
      err: newErrors,
    };
  } else {
    return { err: "" };
  }
};

//method for Tono form validation
export const validateTonoForm = (formData) => {
  const newErrors = {
    eventTyepe: requiredField(formData?.eventType),
    startDate: requiredField(formData?.startDate),
    numberOfShows: requiredField(formData?.numberOfShows),
    venueLocation: requiredField(formData?.venueLocation),
    artist: requiredField(formData?.artist),
    venueName: requiredField(formData?.venueName),
    user: requiredField(formData?.user),
    organizationNumber: requiredField(formData?.organizationNumber),
    companyName: requiredField(formData?.companyName),
    contactName: requiredField(formData?.contactName),
    contactEmail: requiredField(formData?.contactEmail),
    contactPhone: requiredField(formData?.contactPhone),
    invoicePostalCode: requiredField(formData?.invoicePostalCode),
    invoicePostalArea: requiredField(formData?.invoicePostalArea),
    invoiceDeliver: requiredField(formData?.invoiceDeliver),
    postalZipCode: requiredField(formData?.postalZipCode),
    postalArea: requiredField(formData?.postalArea),
    officeZipCode: requiredField(formData?.officeZipCode),
    officeCity: requiredField(formData?.officeCity),
  };

  if (
    newErrors?.eventTyepe ||
    newErrors?.startDate ||
    newErrors?.numberOfShows ||
    newErrors?.venueLocation ||
    newErrors?.venueName ||
    newErrors?.user ||
    newErrors?.organizationNumber ||
    newErrors?.companyName ||
    newErrors?.contactName ||
    newErrors?.contactEmail ||
    newErrors?.contactPhone ||
    newErrors?.invoicePostalCode ||
    newErrors?.invoicePostalArea ||
    newErrors?.invoiceDeliver ||
    newErrors?.postalZipCode ||
    newErrors?.postalArea ||
    newErrors?.officeZipCode ||
    newErrors?.officeCity
  ) {
    if (formData?.user === "Person") {
      if (
        newErrors?.eventTyepe ||
        newErrors?.startDate ||
        newErrors?.numberOfShows ||
        newErrors?.venueLocation ||
        newErrors?.venueName ||
        newErrors?.user ||
        newErrors?.contactName ||
        newErrors?.contactEmail ||
        newErrors?.contactPhone ||
        newErrors?.invoicePostalCode ||
        newErrors?.invoicePostalArea ||
        newErrors?.invoiceDeliver ||
        newErrors?.postalZipCode ||
        newErrors?.postalArea ||
        newErrors?.officeZipCode ||
        newErrors?.officeCity
      ) {
        return { err: newErrors };
      } else {
        return { err: "" };
      }
    } else {
      return { err: newErrors };
    }
  } else {
    return { err: "" };
  }
};

export const signUpForm = (formDate) => {
  const newErrors = {
    username: validateText(formDate?.username),
    firstname: validateText(formDate?.firstname),
    lastname: validateText(formDate?.lastname),
    email: validateEmail(formDate?.email),
    password: validatePassword(formDate?.password),
    confirmPassword: validatePassword(formDate?.confirmPassword)
  }

  if (newErrors?.username ||
    newErrors?.firstname ||
    newErrors?.lastname ||
    newErrors?.email ||
    newErrors?.password ||
    newErrors?.confirmPassword
  ) {
    return { err: newErrors }
  } else {
    return { err: "" }
  }
}