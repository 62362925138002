import { useEffect, useState } from "react";
import Event from "./Event";
import Hero from "components/Hero";
import { getLiveEvents } from "services/api.service";
import Spinner from "components/shared/Spinner";
import { api } from "services";
import { useNavigate } from "react-router-dom";

function Home() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Sorting events based on event date in ascending order
  const sortingData = (events) => {
    const sortedEvents = events?.sort((a, b) => {
      const dateA = new Date(a.get("date"));
      const dateB = new Date(b.get("date"));
      return dateA - dateB;
    });
    setEvents(sortedEvents || []);
    return sortedEvents;
  };

  // Method to fetch live events
  const fetchLiveEvents = async () => {
    try {
      const res = await getLiveEvents();
      sortingData(res);
    } catch (error) {
      console.error("Error fetching live events:", error);
    } finally {
      setIsLoading(false);
    }
  };
  async function checkRolesAccess() {
    try {
      const role = await api.getCurrentUserRoles();
      if (role === "organizer" || role === "admin") {
        return navigate("/dashboard");
      }
    } catch (error) {
      console.log("error ", error);
    }
  }

  useEffect(() => {
    checkRolesAccess();
    fetchLiveEvents();
  }, []);

  if (isLoading) {
    return (
      <div className="container">
        <div className="row py-5 my-5 text-center">
          <Spinner />
        </div>
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="container text-center align-content-center vh-100">
        {/* <div className="row py-5 my-5 text-center"> */}
        <h2>No Events Live currently.</h2>
        {/* </div> */}
      </div>
    );
  }

  return (
    <div className="container mb-5">
      <Hero events={events} />
      <div className="row py-2 my-2 text-center">
        {events.map((event, j) => {
          // if (!event.get("isLive")) return null;
          return (
            <div className="col-md-4 pb-3" key={j}>
              <Event event={event} id={event.id} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Home;
