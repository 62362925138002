
export const addMinutes = (date, minutes) => {
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

export const currencyFormatter = (amount) => {
  var formattedCurrency = new Intl.NumberFormat("nb-NB", {
    maximumFractionDigits: 0,
    style: 'currency',
    currency: 'NOK',
    currencyDisplay: "narrowSymbol"
  }).format(amount);

  return formattedCurrency
}

//method to return the aws object url key
export const getKeyFromS3Url = (url) => {
  // Split the URL by "/" and get the last segment
  const segments = url?.split('/');
  return segments[segments?.length - 1]; // Return the last segment, which is the key
};

//add 2 hours ahead of current time
export const getdateTime = () => {
  const currentDate = new Date();
  currentDate.setHours(currentDate.getHours() + 2);
  return currentDate;
};

// Function to get the start of the next day at 00:00
export const getEndOfDay = (date) => {
  const endOfDay = new Date(date);
  endOfDay?.setHours(23, 59, 59, 999); // Set to the last moment of the day
  return endOfDay;
};


//Custom Header for DatePicker calender
export const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  decreaseYear,
  increaseYear,
}) => {
  return (
    <div className="custom-header">
      <button type="button" className="arrow-button" onClick={decreaseYear}>
        {"<<"}
      </button>
      <button type="button" className="arrow-button" onClick={decreaseMonth}>
        {"<"}
      </button>
      <span className="header-text">
        {date.toLocaleString("default", { month: "long", year: "numeric" })}
      </span>
      <button type="button" className="arrow-button" onClick={increaseMonth}>
        {">"}
      </button>
      <button type="button" className="arrow-button" onClick={increaseYear}>
        {">>"}
      </button>
    </div>
  );
};

export const ButtonSpinner = () => {
  return (
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  )
}