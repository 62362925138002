import axios from "axios";
import { fetchUser } from "./auth.service";
import { auth } from "./index";
import Parse from "./parse.service";
import { getKeyFromS3Url } from "utils";

const Event = Parse.Object.extend("Event");
const Booking = Parse.Object.extend("Booking");
const Ticket = Parse.Object.extend("Ticket");
const Coupon = Parse.Object.extend("Coupon");
const Role = Parse.Object.extend("_Role");
const User = Parse.Object.extend("_User");
const Bank = Parse.Object.extend("Bank");

//create a admin role
export function createAdminRole() {
  const roleACL = new Parse.ACL();
  roleACL.setPublicReadAccess(true);
  roleACL.setPublicWriteAccess(true);
  const adminRole = new Parse.Role("Administrator", roleACL);
  adminRole.save();
}

export const addCoupons = async (coupons, eventId,statusValue) => {
  const currentuser = Parse.User.current();
  console.log("coupons ,eventId",coupons,eventId)

  try {
    // Create an array of promises for saving each coupon
    const savePromises = coupons.map(async (data) => {
      const couponData = {
        code: data?.code,
        type: parseInt(data?.type),
        amount: parseInt(data?.amount),
        isActive: statusValue,
        quantity: parseInt(data?.quantity),
      };

      const coupon = new Coupon();
      // Create a Parse.Object instance for Event with just the object ID
      const eventPointer = Parse.Object.extend("Event");
      const event = new eventPointer();
      event.id = eventId;
      coupon.set("eventId", event);
      coupon.set("organizerId", currentuser);
      coupon.set("used_quantity", 0);

      try {
        // Save the coupon
        const newCoupon = await coupon.save(couponData);
        return newCoupon; // Return the saved coupon
      } catch (error) {
        console.error("Error saving coupon:", error);
        return null; // Return null for failed save
      }
    });

    // Wait for all save operations to complete
    const results = await Promise.all(savePromises);

    // Filter out any null results (failed saves)
    const successfulCoupons = results.filter((result) => result !== null);

    return {
      success: true,
      coupons: successfulCoupons,
    };
  } catch (error) {
    console.error("Error adding coupons:", error);
    return {
      success: false,
      message: "Error adding coupons",
    };
  }
};

export const checkCoupons = async (couponCode, eventId) => {
  console.log("checkCoupons ", couponCode, eventId);
  try {
    const existingCoupon = await getCouponByCode(couponCode, eventId);
    console.log("existingCoupon ", existingCoupon);

    if (existingCoupon) {
      throw { code: 101, message: "Coupon already exists" };
    }
    return existingCoupon;
  } catch (error) {
    console.log("checkCoupons error ", error);
    throw { code: 101, message: "Coupon already exists" };
  }
};

//get all coupons method and return default coupons
export const getCoupon = async () => {
  try {
    const query = new Parse.Query(Coupon);
    const res = await query.find();
    return res;
  } catch (error) {
    console.log("coupon error ", error);
  }
};

export const getOrganizerCoupons = async () => {
  try {
    const user = fetchUser();
    const query = new Parse.Query(Coupon);
    query.equalTo("organizerId", user);
    const res = await query.find();
    return res;
  } catch (error) {
    console.log("getOrganizerCoupons error ", error);
    throw error;
  }
};
//method to fetch the coupon details based on eventId
export const getCouponbyEventId = async (id) => {
  try {
    const couponQuery = new Parse.Query(Coupon);
    const newEvent = new Event();
    newEvent.id = id;
    couponQuery.equalTo("eventId", newEvent);
    const res = await couponQuery.find();
    return res;
  } catch (error) {
    console.log("error ", error);
  }
};

//get coupon by couponId
export const getCouponbyId = async (id) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const res = await query.find();
    return res[0];
  } catch (error) {
    console.log("getCouponbyId Error ", error);
    return error;
  }
};

//update coupon by coupon Id
export const updateCoupon = async (id, newCoupon, eventId) => {
  const getStatus = (status) => {
    if (status === true) {
      return true;
    } else {
      return false;
    }
  };
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponDetails = await query.get(id);

    couponDetails.set("code", newCoupon?.code);
    couponDetails.set("amount", parseInt(newCoupon?.amount));
    couponDetails.set("quantity", parseInt(newCoupon?.quantity));
    couponDetails.set("type", parseInt(newCoupon?.type));
    couponDetails.set("isActive", getStatus(newCoupon?.isActive));
    const res = await couponDetails.save();
    return res;
  } catch (error) {
    console.log("Updating Coupon Data Error ", error);
    return error;
  }
};

//handle the coupon to make active or inactive
export const toggleSwitch = async (id, status) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponDetails = await query.get(id);

    couponDetails.set("isActive", status.get("isActive"));
    const res = await couponDetails.save();
  } catch (error) {
    console.log("toggleSwitch update ", error);
    return error;
  }
};

//delete coupon by coupon ID
export const deleteCoupon = async (id) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponsDetails = await query.get(id);
    const res = await couponsDetails.destroy();
    return res;
  } catch (error) {
    console.log("Delete Coupon Error ", error);
    throw error;
  }
};

//add event method
export async function addEvent(event) {
  console.log("event ",event)
  const currentUser = Parse.User.current();
  const myEvent = new Event();

  const eventObj = {
    title: event?.title,
    description: event?.description,
    date: event?.date,
    max_tickets: event?.max_tickets,
    ticket_types: event?.ticket_types,
    address: event?.address,
    cover_photo: event?.cover_photo || "",
    gate_open_time: event?.gate_open_time,
    gate_close_time: event?.gate_close_time,
    sale_start_date: event?.sale_start_date,
    // sale_end_date: event?.sale_end_date,
    isLive: 0,
  };

  const booking_details = {
    totalTickets: eventObj?.max_tickets,
    max_tickets: eventObj?.max_tickets,
    sold_tickets: 0,
    total_tickets_amount: 0,
   ticket_types_count:{}
  };

  const coupon_details = {};
  try {
    myEvent.set("bookings_count", booking_details);
    myEvent.set("coupons_count", coupon_details);
    myEvent.set("organizerId", currentUser);
    myEvent.set("send_email", false);
    const newEvent = await myEvent.save(eventObj);
    return newEvent;
  } catch (error) {
    console.log("newEvent Error is ", error);
  }
}

//fetching all events and automatically isLive can set to false if the event is outdated
export const getEvents = async () => {
  try {
    const query = new Parse.Query(Event);
    query.include("organizerId");
    const res = await query.find();
    const currentDate = new Date();

    for (let event of res) {
      const eventDate = event.get("date");
      const isLive = event.get("isLive");
      if (eventDate < currentDate && isLive === 1) {
        event.set("isLive", 2);
        // Save the updated event individually
        await event.save();
      }
    }
    return res;
  } catch (error) {
    console.error("Error fetching or updating events:", error);
    throw error;
  }
};

//fetch the only live Events
export const getLiveEvents = async () => {
  try {
    const query = new Parse.Query(Event);
    query.equalTo("isLive", 1);
    const res = await query.find();
    getEvents();
    return res;
  } catch (error) {
    console.error("Error fetching or updating events:", error);
    throw error;
  }
};

//get the Organizerevents based on Organizer Id
export const getEventByUserId = async () => {
  const res = fetchUser();
  const userId = res.id;
  const user = Parse.User.createWithoutData(userId);
  const query = new Parse.Query(Event);
  query.equalTo("organizerId", user);
  try {
    const data = await query.find();
    return data;
  } catch (error) {
    console.log("user error ", error);
  }
};

export function getEventDetail(id) {
  const query = new Parse.Query(Event);
  query.limit(1);
  query.equalTo("objectId", id);
  return query
    .find()
    .then((results) => {
      if (results.length != 0) {
        return results[0];
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.log("getting single event by id error ", error);
    });
}

//event update method
export async function updateEvent(event, id) {
  try {
    const query = new Parse.Query(Event);
    query.limit(1);
    query.equalTo("objectId", id);
    const eventdetails = await query.get(id);

    let updatedBookingCounts = {
      ...eventdetails?.get("bookings_count"),
      totalTickets: event?.max_tickets,
      max_tickets: event?.max_tickets,
    };

    eventdetails.set("title", event?.title);
    // eventdetails.set("ticket_types", event?.ticket_types);
    eventdetails.set("ticket_types",event?.ticket_types);
    eventdetails.set("description", event?.description);
    eventdetails.set("cover_photo", event?.cover_photo);
    eventdetails.set("max_tickets", event?.max_tickets);
    eventdetails.set("address", event?.address);
    eventdetails.set("date", event?.date);
    eventdetails.set("bookings_count", updatedBookingCounts);
    eventdetails.set("gate_open_time", event?.gate_open_time);
    eventdetails.set("gate_close_time", event?.gate_close_time);
    eventdetails.set("sale_start_date", event?.sale_start_date);
    const res = await eventdetails.save();
    return res;
  } catch (error) {
    console.log("Ticket Upadate Error is ", error);
  }
}

//make event live method
export async function postEventLive(id) {
  try { 
    const query = new Parse.Query(Event);
    query?.equalTo("objectId", id);
    const eventDetails = await query?.get(id);
    eventDetails.set("isLive", 1);
    const eventData = await eventDetails?.save();
     //method to making all coupons live when event go live
     const couponData = await getCouponbyEventId(id);
     const couponPromises = couponData.map(async (item) => {
       if (item?.get("isActive")) {
         return true;
       } else {
         item.set("isActive", true);
         return item.save(); 
       }
     });
 
    //all coupons are saving
     await Promise.all(couponPromises);
    return eventData;
  } catch (error) {
    console.log("Event Golive Error ", error);
  }
}

//delete event method
export async function deleteEventbyId(id) {
  const query = new Parse.Query(Event);
  query.equalTo("objectId", id);
  try {
    const eventdetais = await query.get(id);
    const imgUrl = eventdetais?.get("cover_photo");
    const objectKey = await getKeyFromS3Url(imgUrl);
    const checkImgexist = await checkObject(objectKey);
     if(checkImgexist?.exists){
      const deleteObject = await deleteObjectFromS3(objectKey);
      console.log("deleteObject ",deleteObject)
     }
      const res = await eventdetais.destroy();
      return res;
  
  } catch (error) {
    console.log("Deleting Event Error ", error);
  }
}

export const updateCouponQuantity = async (couponCode, eventId) => {
  // console.log("couponCode eventId",couponCode,eventId)
  try {
    const query = new Parse.Query(Coupon);
    const event = new Event();
    event.id = eventId;

    query.equalTo("code", couponCode);
    query.equalTo("eventId", event);
    const res = await query.find();
    const couponQuery = res[0];
    if (couponQuery) {
      let quantity = couponQuery?.get("quantity");
      let active = couponQuery?.get("isActive");
      if (quantity > 0 && active) {
        let updatedQuantity = quantity - 1;
        let usedQuantity = (couponQuery?.get("used_quantity") ?? 0) + 1;
        couponQuery?.set("quantity", updatedQuantity);
        couponQuery?.set("used_quantity", usedQuantity);
        if (updatedQuantity === 0) {
          couponQuery?.set("isActive", false);
        }
        const res = await couponQuery?.save();
        return res;
      } else {
        console.log("quantity is nagative  or zero not active ");
      }
      return couponQuery;
    }
  } catch (error) {
    console.log("error ", error);
    throw error;
  }
};

//update event Tickets count and types of Tickets count and coupons count
export async function eventTicketUpdate(bookingData) {
  if (bookingData) {
    let {
      eventId,
      totalTickets,
      totalAmount,
      ticketSoldOut,
      ticketTypes,
      couponCode,
    } = bookingData;
    const max_ticket_total = totalTickets;
    try {
      const query = new Parse.Query(Event);
      query.limit(1);
      query.equalTo("objectId", eventId);
      const eventObject = await query.first();

      if (!eventObject) {
        return;
      }

      if (eventObject) {
        let eventbookingCount = eventObject?.get("bookings_count");
        // let eventobj = eventbookingCount?.ticket_types_count;

        // //updaing ticket types count
        // let eventUpdatedObject = {
        //   Ordinær: eventobj?.["Ordinær"] + ticketTypes.type1,
        //   "Student & U18": eventobj?.["Student & U18"] + ticketTypes.type2,
        //   Ledsager: eventobj?.["Ledsager"] + ticketTypes.type3,
        // };

        // eventbookingCount.ticket_types_count = eventUpdatedObject;
        eventbookingCount.max_tickets = totalTickets;
        eventbookingCount.sold_tickets = eventbookingCount.sold_tickets + ticketSoldOut;
        eventbookingCount.total_tickets_amount = eventbookingCount.total_tickets_amount + totalAmount;

        eventObject.set("bookings_count", eventbookingCount);

        //updating tickets count
        eventObject.set("max_tickets", max_ticket_total);
        let eventCouponObject = eventObject?.get("coupons_count");

        //updating coupons count
        if (couponCode) {
          eventCouponObject[couponCode] =
            (eventCouponObject[couponCode] || 0) + 1;
        }

        //updating coupon quantity
        const couponQuantity = await updateCouponQuantity(couponCode, eventId);

        const res = await eventObject.save();
        return res.toJSON();
      } else {
        console.log("event object is ", eventObject);
      }
    } catch (error) {
      console.log("error ", error);
      throw error;
    }
  }
}

export function pdfGenerate() {
  return Parse.Cloud.run("generateTicket")
    .then((res) => res)
    .catch((err) => err);
}

export function createBooking(eventId, amount, coupon) {
  const booking = new Booking();
  booking.set("userId", auth.fetchUser());
  booking.set("status", "open");
  booking.set("amount", amount);
  booking.set("coupon", coupon);

  const event = new Event();
  event.id = eventId;
  booking.set("eventId", event);

  return booking.save().then(
    (booking) => {
      return booking;
    },
    (error) => {
      return error.message;
    }
  );
}

export function updateBookingPayment(bookingId) {
  return getBookingById(bookingId).then((booking) => {
    booking.set("status", "complete");
    return booking.save();
  });
}

export function updateBookingTicketLink(bookingId, link) {
  return getBookingById(bookingId).then((booking) => {
    booking.set("ticket_url", link);
    return booking.save();
  });
}

export async function getBookings() {
  try {
    const query = new Parse.Query(Booking);
    // Check for userid
    query.equalTo("userId", auth.fetchUser());
    query.include("eventId");
    query.include("userId");
    query.addDescending("createdAt");

    const results = await query.find();

    // Return results if not empty, otherwise null
    return results.length > 0 ? results : null;
  } catch (error) {
    console.error("Error fetching bookings: ", error);
    // Optionally, rethrow or handle the error based on your use case
    throw error; // or simply return null if you prefer
  }
}

export function getBookingById(id) {
  const query = new Parse.Query(Booking);
  query.equalTo("objectId", id);
  query.limit(1);
  query.include("eventId");
  return query
    .find()
    .then((bookings) => {
      if (bookings.length != 0) {
        return bookings[0];
      }
      return null;
    })
    .catch((err) => err);
}

//get all Event bookings of organizer or admin based on user ID
export const getOrganizerAllBookings = async (organizerID) => {
  try {
    //creating organizer Pointer
    const organizerPointer = new Parse.User();
    organizerPointer.id = organizerID;

    //creating Event POinter
    const event = new Parse.Query(Event);
    event.equalTo("organizerId", organizerPointer);

    const booking = new Parse.Query(Booking);
    booking.matchesQuery("eventId", event);
    booking.include("userId");

    const result = await booking.find();

    return result;
  } catch (error) {
    console.log("error ", error);
  }
};
export const getAllBookings = async () => {
  try {
    //creating organizer Pointer
   
    //creating Event POinter
    const event = new Parse.Query(Event);

    const booking = new Parse.Query(Booking);
    booking.matchesQuery("eventId", event);
    booking.include("userId");
    booking.include("eventId");

    const result = await booking.find();

    return result;
  } catch (error) {
    console.log("error ", error);
  }
};

export async function createTicket(bookingId) {
  const booking = await getBookingById(bookingId);
  const event = booking?.get("eventId");
  const address = event?.get("address");
  const venue = address?.split(",")[0];
  const splitAddress = address?.split(" ");
  const city = splitAddress[splitAddress.length - 1];
  const user = auth.fetchUser();

  const params = {
    ticketId: booking.id,
    title: event?.get("title"),
    date: event?.get("date"),
    time: "19:00",
    city: city,
    venue: venue,
    address: address,
    price: booking?.get("amount"),
    email: user?.get("email"),
  };

  return await Parse.Cloud.run("createTicket", params);
}

export function getCouponByCode(_code, eventId) {
  const query = new Parse.Query(Coupon);
  const event = new Event();
  event.id = eventId;
  query.equalTo("code", _code);
  query.equalTo("eventId", event);
  query.limit(1);
  return query
    .find()
    .then((results) => {
      if (results.length != 0) {
        let coupon = results[0];
        let isActive = coupon?.get("isActive");
        if (isActive) {
          return results[0];
        } else {
          return null;
        }
      }
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
}

export function applyCoupon(_code) {
  const query = new Parse.Query(Coupon);
  query.equalTo("code", _code);
  query.limit(1);
  return query
    .find()
    .then((results) => {
      if (results.length != 0) return results[0];
      return null;
    })
    .catch((err) => {
      console.log(err);
    });
}

//get the current user role name
export const getCurrentUserRoles = async () => {
  const currentUser = Parse.User.current();
  if (!currentUser) {
    return null;
  }
  const query = new Parse.Query(Role);
  query.equalTo("users", currentUser);
  const userRoles = await query.find();
  const res = userRoles.map((role) => role.get("name"));

  return res[0];
};

//get the all organizer events base on organizer Id
export const organizerEvents = async (id) => {
  try {
    const organizerPointer = new Parse.User();
    organizerPointer.id = id;

    const query = new Parse.Query(Event);
    query.equalTo("organizerId", organizerPointer);

    const res = await query.find();
    return res;
  } catch (error) {
    console.log("error ", error);
  }
};

//get the user information based on userId
export const getUserDetails = async (userId) => {
  try {
    const query = new Parse.Query(Parse.User);
    query.equalTo("objectId", userId);
    const user = await query.first();

    if (!user) {
      return null;
    }
    return user;
  } catch (error) {
    throw error;
  }
};

//method to check the email is exist in data base or not for creatinf new user
export const getUserEmail = async (email) => {
  const existingUser = await Parse.Cloud.run("getUserByEmail", { email });
  return existingUser;
};

//method to get fetch the event organization information for tono mail
export const fetchOragnizerInformation = async (eventId) => {
  const res = await getEventDetail(eventId);
  const singleEvent = res;
  let event_information = {
    event_name: singleEvent?.get("title"),
    event_address: singleEvent?.get("address"),
    event_start_date: singleEvent?.get("date"),
    total_tickets_count: singleEvent?.get("bookings_count")?.sold_tickets,
    audient_count: singleEvent?.get("bookings_count")?.sold_tickets,
    gross_revenue: singleEvent?.get("bookings_count")?.total_tickets_amount,
  };

  const userdetails = singleEvent?.get("organizerId");
  let organizer_information = {
    organizer_name:
      //   userdetails?.get("profileId")?.get("firstname") +
      //   userdetails?.get("profileId")?.get("lastname"),
      //   organizer_name:
      userdetails?.get("username"),
    organizer_phone: userdetails?.get("profileId")?.get("phone"),
    organizer_country: userdetails?.get("profileId")?.get("country"),
    organizer_email: userdetails?.get("email"),
    organizer_address: userdetails?.get("profileId")?.get("address"),
  };

  let event_organizer_detiails = {
    ...organizer_information,
    ...event_information,
  };

  return event_organizer_detiails;
};

//method to update the tono pdf send status
export const sendEmailStatus = async (id) => {
  try {
    const query = new Parse.Query(Event);
    const event = await query.get(id);

    event.set("send_email", true);
    const res = await event.save();
    return res;
  } catch (error) {
    console.log("event error ", error);
    return error;
  }
};

//method to add the organizer bank details
export const addBankDetails = async (bankdata) => {
  const currentUser = Parse.User.current();
  try {
    const bank = new Bank();
    bank.set("organizerId", currentUser);
    const res = await bank.save(bankdata);
    return res;
  } catch (error) {
    console.log("addBankDetails Error", error);
    throw error;
  }
};

//method to fetch the user bank details based on userId
export const getBankDetails = async () => {
  try {
    let currentUser = fetchUser();
    const BankQuery = new Parse.Query(Bank);
    BankQuery.equalTo("organizerId", currentUser);
    const res = await BankQuery?.find();
    // console.log("getBankDetails ",res)
    return res[0];
  } catch (error) {
    console.log("getBankDetails Error ", error);
    throw error;
  }
};

//method to update the bank acount details
export const updateBankDetails = async (BankDetails, id) => {
  try {
    let currentUser = fetchUser();
    const BankQuery = new Parse.Query(Bank);
    BankQuery.equalTo("organizerId", currentUser);

    const bankdetails = await BankQuery.get(id);
    bankdetails.set("accountName", BankDetails?.accountName);
    bankdetails.set("accountNumber", Number(BankDetails?.accountNumber ?? 0));
    bankdetails.set("confirmAccountNumber",Number(BankDetails?.confirmAccountNumber ?? 0));
    bankdetails.set("accountType", BankDetails?.accountType);
    bankdetails.set("location", BankDetails?.location);
    bankdetails.set("code", BankDetails?.code);

    const res = await bankdetails.save();
    return res;
  } catch (error) {
    console.log("updateBankDetails Error ", error);
    throw error;
  }
};

// //method to add the image in aws s3
// export const addImagetoS3 = async (filename,file)=>{
//   let obj ={filename:filename,file:file}
//   console.log("obj ",obj)
//   return await Parse.Cloud.run("addImage", obj);
// }

//method to delete the image from aws s3 and in event
export const deleteObjectFromS3 = async (filename, eventId) => {
  try {
    const response = await Parse.Cloud.run("deleteObject", { key: filename });
    console.log("deleteObjectFromS3 ",response)
    if (response?.success) {
      const eventQuery = new Parse.Query(Event);
      const event = await eventQuery?.get(eventId);
      if (event) {
        event.set("cover_photo", "");
        await event.save();
        console.log("Event updated successfully");
      } else {
        console.error("Event not found");
      }
    }

    return response;
  } catch (error) {
    console.error("Error in deleteObjectFromS3: ", error);
  }
};

export const checkObject = async (filename) => {
  const response = await Parse.Cloud.run("checkObject", { key: filename });
  return response;
};