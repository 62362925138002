import React, { useEffect, useState } from "react";
import { getUserEmail, requestPasswordReset } from "services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import { api } from "services";
import { useTranslation } from "react-i18next";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const {t} = useTranslation('forgetpassword');

  //method to check if user is exist in database before sending forget password mail
  const checkEmail = async () => {
    try {
      const res = await api.getUserEmail(email);
      console.log("res ", res);
      return res;
    } catch (error) {
      console.log("error is ", error.message);
      setError(error?.message);
      throw error;
    }
  };

  //handle reset password method
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const res = await checkEmail();
      await requestPasswordReset(email);
      setSuccess(t("message.success"))
      setTimeout(() => {
        navigate("/");
      }, "2000");
    } catch (err) {
      if (err?.message == "User not found") {
        setError(t("message.error"))
      } else {
        setError("Error sending password reset email");
      }
      console.log(err?.message);
    }
  };

  return (
    <div className="container py-5 my-5">
      <div className="row ">
        <div className="col-md-4 offset-md-4">
          <h2 className="py-3 text-center">
           {t('title')}
          </h2>

          <form>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control py-3"
             placeholder={t('placeholder.email')}
            />
            {success && <div className="success">{success}</div>}
            <p className="text-start">
              {error && <div className="error">{error}</div>}
            </p>
            <div className="d-grid">
              <button
                type="submit"
                onClick={handlePasswordReset}
                className="py-3 btn btn-outline-secondary btn-block fw-bold"
              >
                {t('btn')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;