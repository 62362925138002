import Spinner from "components/shared/Spinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, auth } from "services";
import { currencyFormatter } from "utils";
import { useTranslation } from "react-i18next";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [currentpagenumber, setCurentPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedType, setSelectedType] = useState(1);
  const [userRole, setUserRole] = useState(null);
  const {t} = useTranslation("dashboard");
  const navigate = useNavigate();
  const step_number = 5;

  // const sortingAmount = (events) => {
  //   const publshedData = events?.sort((a, b) => {
  //     const amountA = a?.get("bookings_count").total_tickets_amount;
  //     const amountB = b?.get("bookings_count").total_tickets_amount;
  //     return !sorting ? amountA - amountB : amountB - amountA;
  //   });
  //   return publshedData;
  // };

  //sorting events based on date in ascending order based on creation event time
  const sortingDate = (events) => {
    const publshedData = events?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
    return publshedData;
  };
  //events filtered based on the events which are live and completed but not drafted
  const filteredEvents = (events) => {
    let filterdevents = events?.filter((item) => {
      return item?.get("isLive") === Number(selectedType);
    });

    let sortedEvents = sortingDate(filterdevents);
    setEvents(sortedEvents);
  };
  //fetch the events based on user role
  const fetchEvents = async () => {
    try {
      setLoading(true);
      const role = await api.getCurrentUserRoles();
      setUserRole(role);
      if (role === "admin") {
        const res = await api.getEvents();
        filteredEvents(res);
      }
      if (role === "organizer") {
        const user = auth.fetchUser();
        const res = await api.organizerEvents(user?.id);
        filteredEvents(res);
      }
    } catch (error) {
      console.log("fetchEvents error ", error);
    } finally {
      setLoading(false);
    }
  };

  //handle pagination Prev page method
  const handlePrev = () => {
    if (currentpagenumber === 1) return;
    setCurentPageNumber((prev) => prev - 1);
  };

  //handle pagination next page method
  const handleNext = async () => {
    let finalPagenumber = events.length / step_number;
    if (currentpagenumber === finalPagenumber) return;
    setCurentPageNumber((prev) => prev + 1);
  };

  useEffect(() => {
    fetchEvents();
  }, [selectedType]);

  //based on selecting page number in pagination in the page will set
  // useEffect(() => {
  //   const start = (currentpagenumber - 1) * step_number;
  //   const end = currentpagenumber * step_number;
  //   setData(events?.slice(start, end));
  // }, [currentpagenumber, events]);

// method to set the pagination page number
  const handlePageNumber = (value) => {
    let isValidNumber = Math.ceil(events.length / step_number);
    if (value <= isValidNumber && value >= 1) {
      setCurentPageNumber(value);
    }
  };

  if (loading) return <Spinner />;
  return (
    <div className="bg-white border rounded-3 shadow-sm p-3 w-100 mb-lg-0 mb-2">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="text-md-start">{t("event.title")}</h3>
        <div className="d-flex align-items-center">
          <p
            className="text-center align-items-center text-decoration-underline fw-bold mb-0 cursor"
            onClick={() => navigate("/events")}
          >
           {t("event.viewAll")}
          </p>
          <select
            className="form-select form-select-sm ms-3"
            style={{ width: "100px" }}
            aria-label="Filter by event type"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <option value="1">{t("event.status.live")}</option>
            <option value="2">{t("event.status.completed")}</option>
            <option value="0">{t("event.status.draft")}</option>
          </select>
        </div>
      </div>

      <div className="mb-3 table-responsive rounded-3  mt-4 ">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center" scope="col">
                #
              </th>
              <th scope="col">{t("event.eventName")}</th>
              {userRole === "admin" && (
                    <th scope="col">Organizer</th>
                  )}
              {/* <th scope="col">Tickets</th> */}
              {/* <th scope="col">Ticket Left</th> */}
              {/* <th scope="col">Sold Tickets</th> */}
              <th scope="col" className="d-flex cursor">
                {t("event.amount")}
              </th>
              <th scope="col">{t("event.tono.title")}</th>
            </tr>
          </thead>
          <tbody>
            {events.length > 0 ? (
              events?.map((item, index) => {
                // console.log(item?.get("bookings_count")?.total_tickets_amount);

                return (
                  <tr key={index}>
                    <th className="px-3" scope="row">
                      {(currentpagenumber - 1) * step_number + index + 1}
                    </th>
                    <td>
                      <p className="my-0 py-0">{item?.get("title")}</p>
                      {item?.get("isLive") === 1 ? (
                        <p className="my-0 py-0 text-success">{t("event.status.live")}</p>
                      ) : item?.get("isLive") === 2 ? (
                        <p className="my-0 py-0 text-danger">{t("event.status.completed")}</p>
                      ) : (
                        <p className="my-0 py-0 text-secondary">{t("event.status.draft")}</p>
                      )}
                    </td>
                    {userRole === "admin" && (
                          <td>
                            {item?.get("organizerId")?.get("username") ||
                              "Not Found"}
                          </td>
                        )}
                    {/* <td>
                      {item?.get("bookings_count")?.sold_tickets}/
                      {item?.get("bookings_count")?.totalTickets}
                    </td> */}
                    {/* <td>{item?.get("max_tickets")}</td> */}
                    {/* <td>{item?.get("bookings_count")?.sold_tickets}</td> */}
                    <td>
                      {currencyFormatter(
                        item?.get("bookings_count")?.total_tickets_amount ?? 0
                      )}
                    </td>
                    <td>
                      {item?.get("send_email") && item?.get("isLive") === 2 ? (
                        <button
                          type="button"
                          className="btn btn-outline-secondary w-100"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          {t("event.tono.submitted")}
                        </button>
                      ) : item?.get("isLive") === 2 ||
                        item?.get("isLive") === 1 ? (
                        <button
                          className="btn btn-outline-secondary"
                          disabled={item?.get("isLive") === 1}
                          onClick={() => navigate(`/tono/${item?.id}`)}
                        >
                         {t("event.tono.generateReport")}
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  {t("event.noEvent")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div>
          {/* <nav aria-label="Page navigation example">
            <ul class="pagination cursor float-end">
              <li className="page-item mx-2">
                <a
                  className="page-link rounded-3"
                  aria-label="Previous"
                  onClick={handlePrev}
                >
                  <span className="text-dark" aria-hidden="true">
                  
                    Prev
                  </span>
                </a>
              </li>
             

              <li className="page-item cursor" onClick={handleNext}>
                <a
                  className="page-link text-dark mx-2 rounded-3"
                  aria-label="Next"
                >
                  <span aria-hidden="true">
                  
                    Next
                  </span>
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>

      {/* Model */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="fw-bolder">{t("event.tono.title")}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {t("event.tono.description")}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {t("event.closebtn")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
