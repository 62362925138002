import React, { useEffect, useState } from "react";
import { api } from "services";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ButtonSpinner, CustomHeader, getdateTime, getEndOfDay, getKeyFromS3Url } from "utils";
import { addImagetoS3 } from "services/stripe.service";
import imglogo from "../../images/imglogo.jpg";
import DatePicker from "react-datepicker";
import ConfirmDelete from "../popups/confirmDelete";
import "react-datepicker/dist/react-datepicker.css";
import { Eventvalidation } from "views/validation/Event";

const AddEvent = ({ handleNext }) => {
  const [eventDate, setEventDate] = useState(new Date());
  const [eventGateOpenTime, setevntGateOpenTime] = useState(new Date());
  const [isimage, setIsImage] = useState("");
  const [errors, setErrors] = useState({});
  const [action, setAction] = useState({});
  const [file, setFile] = useState();
  const [ticketType, setTicketType] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleteTyp0, setIsDeleteType0] = useState(false);
  const [updateTicketTicketType, setUpdateTicketType] = useState(null);
  const [loader, setLoader] = useState(false);
  const [savebtnLoader, setSavebtnLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false)
  const [event, setEvent] = useState({
    title: "",
    description: "",
    address: "",
    date: getdateTime(),
    sale_start_date: new Date(),
    sale_end_date: new Date(),
    gate_open_time: new Date(),
    gate_close_time: new Date(),
    error: "",
    max_tickets: 0,
    ticket_types: [],
    file: {},
    cover_photo: "",
  });
  const { t } = useTranslation("addevent");
  const { validateEventForm } = Eventvalidation();
  const navigate = useNavigate();
  const params = useParams();
  const Id = params?.id;
  const regex = /^(|0|[1-9]\d*|[0-9]+)$/;

  //Fetching Event based on Event Id and converting object ticket types to array ticket types
  const fetchEventById = async (id) => {
    const event = await api.getEventDetail(id);
    const ticketTypes = event?.get("ticket_types") || {};
    const ticketTypesArray = Object.values(ticketTypes)

    // Updating the array of ticketTypes in event
    const updatedEventDetails = {
      ...event?.attributes,
      ticket_types: ticketTypesArray,
    };

    setEvent(updatedEventDetails);
    setIsImage(event?.get("cover_photo"));
  };

  useEffect(() => {
    if (Id) {
      fetchEventById(Id);
    }
    //  else {
    //   //first type updating the ticket_types with default type0 with ticketType name is All
    //   setEvent((prev) => ({
    //     ...prev,
    //     ticket_types: [
    //       ...(event?.ticket_types || []),
    //       { type: 0, name: "All", amount: 0 },
    //     ],
    //   }));
    // }
  }, []);

  //handle go back
  const goBack = () => {
    navigate(-1);
  };

  //handle reset Events
  const resetEvent = (e) => {
    e.preventDefault();
    setEvent({
      title: "",
      description: "",
      address: "",
      date: getdateTime(),
      error: "",
      ticket_types: [],
      max_tickets: 0,
      file: {},
      cover_photo: event?.cover_photo || "",
      ticket_types: []
    });
  };

  //method to check the image exist in aws
  const hadleCheckfile = async (coverPhoto) => {
    try {
      if (coverPhoto !== "") {
        const key = getKeyFromS3Url(coverPhoto);
        const res = await api.checkObject(key);
        return res;
      }
    } catch (error) {
      console.log("hadleCheckfile error ", error);
    }
  };

  // Upload the file to S3
  const handleUpoadImage = async (coverPhoto) => {
    if (event?.cover_photo) {
      const checkingImg = await hadleCheckfile(coverPhoto);
      //if image is not present in aws and event cover_photo not empty then image will add in aws and update the parse database
      if (!checkingImg?.exists && event?.cover_photo !== "") {
        const response = await addImagetoS3(file);
        if (response?.success) {
          if (isimage) {
            let key = getKeyFromS3Url(isimage);
            //it will delete the old existing image from aws when we try to change the new image in existing event
            await api.deleteObjectFromS3(key);
            setIsImage("");
          }
          return response;
        } else {
        }
      }
    }
  };

  //method to add event(Id not present from url) and upadate event(if Id present from url) on clicking Next button
  const handleUpdateEvent = async () => {
    const { err } = validateEventForm(event);
    console.log("err ", err)
    if (!err) {
      try {
        setLoader(true);
        if (Id) {
          const eventUpdated = event;
          //method to upload the image in to aws s3
          const res = await handleUpoadImage(event?.cover_photo);
          eventUpdated.cover_photo = res?.imageUrl;
          let ticketTypes = event?.ticket_types;
          const object = {};
          ticketTypes?.forEach((item) => {
            object[item?.name] = item;
          });
          eventUpdated.ticket_types = object;
          const updatedEventResponse = await api.updateEvent(eventUpdated, Id);
          //this method will call the stepper next method
          handleNext(updatedEventResponse);
        } else {
          const newEvent = event;
          //method to upload the image in aws s3
          const response = await handleUpoadImage(event?.cover_photo);
          newEvent.cover_photo = response?.imageUrl;

          const result = event?.ticket_types?.reduce((acc, item) => {
            acc[item.name] = {
              type: item.type,
              value: item.value,
              name: item.name,
            };
            return acc;
          }, {});
          newEvent.ticket_types = result;
          const res = await api.addEvent(newEvent);
          //this method will call the stepper next method
          handleNext(res);
          setErrors({});
        }
      } catch (error) {
        console.log("handleUpdateEvent Error ", error);
      } finally {
        setLoader(false);
      }
    } else {
      setErrors(err);
    }
  };

  //adding new Event and updating new event
  const createEvent = async (e) => {
    e.preventDefault();
    try {
      setSavebtnLoader(true)
      if (Id) {
        //updating existing event
        const eventUpdated = event;
        const res = await handleUpoadImage(event?.cover_photo);
        eventUpdated.cover_photo = res?.imageUrl;
        const ticketTypes = event?.ticket_types;
        const object = {};
        ticketTypes?.forEach((item) => {
          object[item?.name] = item;
        });
        eventUpdated.ticket_types = object;
        await api.updateEvent(eventUpdated, Id);
      } else {
        //adding new event
        const newEvent = event;
        //it will check if image is exist in aws or not if exist dont add image in aws else it will add in aws and update parse dashboard
        const res = await handleUpoadImage(event?.cover_photo);
        newEvent.cover_photo = res?.imageUrl;
        const result = event?.ticket_types?.reduce((acc, item) => {
          acc[item.name] = {
            type: item.type,
            value: item.value,
            name: item.name,
          };
          return acc;
        }, {});
        newEvent.ticket_types = result;
        await api.addEvent(newEvent);
      }
      navigate("/events");
    } catch (err) {
      console.log("Creating or Updating Event Error ", err);
    } finally {
      setSavebtnLoader(false)
    }
  };

  //handle update all events
  const updateEvent = (e, index) => {
    const { name, value } = e.target;
    if (name === "max_tickets") {
      if (
        regex.test(
          value
        )
      ) {
        setEvent({
          ...event,
          [name]: Number(value),
        });
      }
    } else {
      setEvent({
        ...event,
        [name]: value,
      });
    }
    if (name === "date") {
      setEventDate(value);
    }
    if (name === "gate_open_time") {
      setevntGateOpenTime(value);
    }
  };

  // handle update cover photo
  const handleImage = async (e) => {
    e.preventDefault();
    const file = e?.target?.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }
    setFile(file);

    try {
      setImageLoader(true)
      const reader = new FileReader();
      reader.onload = async () => {
        setEvent((prev) => ({
          ...prev,
          cover_photo: reader?.result,
          file: {
            type: file.type,
            name: file.name,
          },
        }));
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error during image processing:", error);
    } finally {
      setImageLoader(false)
    }
  };

  // delete Image
  const deleteImage = async () => {
    setImageLoader(true)
    setEvent((prev) => ({
      ...prev,
      ["cover_photo"]: "",
    }));
    if (event?.cover_photo !== "") {
      const key = getKeyFromS3Url(event?.cover_photo);
      try {
        const res = await api.deleteObjectFromS3(key, Id);
      } catch (error) {
        console.log("deleteImage error ", error);
      } finally {
        setImageLoader(false)
      }
    }
  };

  //events form max tickets and event shedule date and time
  const ticketCountAndScheduleRow = () => {
    return (
      <div className="row">
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("eventShedule")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            minDate={new Date()}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MM/d/yyyy HH:mm"
            isClearable
            name="date"
            selected={event?.date}
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "date" } })
            }
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              decreaseYear,
              increaseYear,
            }) => (
              <CustomHeader
                date={date}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
              />
            )}
          />
          <p className="text-danger">{errors && errors?.date}</p>
        </div>
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("gateOpenTime")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            showTimeSelectOnly
            minTime={eventDate}
            maxTime={getEndOfDay(eventDate)} // Maximum selectable time set to 23:59:59
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat=" HH:mm"
            showDateSelect={false}
            name="gate_open_time"
            selected={event?.gate_open_time}
            placeholderText="Select Gate Open Time"
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "gate_open_time" } })
            }
          />
          <p className="text-danger">{errors && errors?.gate_open_time}</p>
        </div>
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("gateCloseTime")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            showTimeSelectOnly
            minTime={eventGateOpenTime}
            maxTime={getEndOfDay(new Date())}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat=" HH:mm"
            name="gate_close_time"
            selected={event?.gate_close_time || ""}
            placeholderText="Select Gate Close Time"
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "gate_close_time" } })
            }
          />
          <p className="text-danger">{errors && errors?.gate_close_time}</p>
        </div>
        <div className="col-md-4 col-12">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("ticketSaleStart")}
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            showTimeSelect
            minDate={new Date()}
            maxDate={new Date(event?.date)}
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MM/d/yyyy HH:mm"
            isClearable
            name="sale_start_date"
            selected={event?.sale_start_date}
            placeholderText="Select Sale Start Date"
            onChange={(date) =>
              updateEvent({ target: { value: date, name: "sale_start_date" } })
            }
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              decreaseYear,
              increaseYear,
            }) => (
              <CustomHeader
                date={date}
                decreaseMonth={decreaseMonth}
                increaseMonth={increaseMonth}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
              />
            )}
          />
          <p className="text-danger">{errors && errors?.sale_start_date}</p>
        </div>
        <div className="col-md-4 col-12 ">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            {t("maxTickets")}
          </label>
          <input
            type={Number}
            value={event?.max_tickets}
            onChange={updateEvent}
            className="form-control py-2 bg-transparent border-secondary-subtle"
            id="ticketCountInput"
            placeholder={t("placeholder.maxTickets")}
            name="max_tickets"
          />
          <p className="text-danger mb-0 pb-0">
            {errors && errors?.max_tickets}
          </p>
        </div>
      </div>
    );
  };

  //Admin form buttons
  const ButtonsRowHtml = () => {
    return (
      <div className="py-md-2 row mt-3">
        {/* reset */}
        <div className="col-4 d-grid">
          <button
            type="reset"
            onClick={resetEvent}
            className="btn btn-outline-secondary btn-block py-3 fw-bold"
          >
            {t("reset")}
          </button>
        </div>
        {/* save */}
        <div className="col-4 d-grid">
          {Id ? (
            <button
              className="btn btn-outline-secondary btn-block py-3 fw-bold"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#confirmDeleteModal"
              onClick={(e) => {
                setAction({
                  actionName: createEvent,
                  actionBody: "Are you sure you want to Update",
                  actionTitle: "Update Action",
                });
              }}
            >
              {savebtnLoader ? <ButtonSpinner /> : t("save")}
            </button>
          ) : (
            <button
              type="button"
              onClick={createEvent}
              className="btn btn-outline-secondary btn-block py-3 fw-bold"
            >
              {savebtnLoader ? <ButtonSpinner /> : t("save")}
            </button>
          )}
        </div>
        {/* next */}
        <div className="col-4 d-grid">
          <button
            type="button"
            className="btn btn-outline-secondary btn-block py-3 fw-bold"
            onClick={handleUpdateEvent}
          >
            {loader ? <ButtonSpinner /> : t("next")}
          </button>
        </div>
      </div>
    );
  };

  //method to check the duplicate ticket types
  const checkTicketTypeExist = (ticketTypes, newTicketType) => {
    const res = ticketTypes.filter((item) => item?.name == newTicketType?.name);
    return res;
  }

  //method to update the event ticket types
  const handleTicketTypes = () => {
    console.log("ticektType ", ticketType);
    console.log("event ", event)
    if (ticketType == "" || ticketType == undefined) {
      setErrors((prev) => ({
        ...prev,
        ["ticketName"]: "Please Enter the Ticket Type"
      }))
    } else if (event?.ticket_types.length > 3) {
      console.log("You reached the maximum ticket types ", event?.ticket_types);
      setErrors((prev) => ({
        ...prev,
        ["ticketName"]: "Ticket Types shouldn't be exceed the max 4 Ticket Types"
      }));
      setTicketType("")

    } else {
      setErrors((prev) => ({
        ...prev,
        ["ticketName"]: ""
      }))
      const updatedTicketTypes = [...event?.ticket_types] || [];
      if (isEdit) {
        const { ticket, index } = updateTicketTicketType;
        updatedTicketTypes[index] = {
          type: ticket?.type,
          value: ticket?.value,
          name: ticketType,
        };
      } else {
        const lastTicketType = updatedTicketTypes[updatedTicketTypes?.length - 1];
        const newTicketType = {
          type: (lastTicketType?.type ?? 0) + 1,
          value: null,
          name: ticketType,
        };

        const exists = checkTicketTypeExist(updatedTicketTypes, newTicketType);
        if (exists?.length === 0) {
          updatedTicketTypes.push(newTicketType);
        } else {
          console.log("Ticket type already exists");
          return; // Exit early if the ticket type already exists
        }
      }

      // Update the state once after modifications
      setEvent((prev) => ({
        ...prev,
        ticket_types: updatedTicketTypes,
      }));

      setIsEdit(false);
      setTicketType("");
    }

  };

  //method to delete the dynamic ticket type
  const handleDeleteTicketType = (indexnumber, objkey) => {
    if (indexnumber === 0) {
      setIsDeleteType0(true);
    }
    let updatedTicketTypes = event?.ticket_types?.filter(
      (item) => item?.type !== objkey?.type
    );
    setEvent((prev) => ({
      ...prev,
      ticket_types: updatedTicketTypes,
    }));
    setIsEdit(false);
    setTicketType("")
  };

  //method to update the dynamic ticket type
  const handleTicktPrices = (e, item, index) => {
    const { name, value } = e.target;
    // Determine if the value is valid or not
    const isValid = regex.test(value);

    // Create the updated array based on the validation result
    const updatedArray = event?.ticket_types.map((ticketType) => {
      if (ticketType?.name === name) {
        return { ...ticketType, value: isValid ? value : "" };
      }
      return ticketType;
    });

    // Set the updated ticket types in the state
    setEvent((prev) => ({
      ...prev,
      ticket_types: updatedArray,
    }));
  };

  //method to edit the ticket types
  const handleEditTicketType = (typeNumber, index) => {
    let ticketType = event?.ticket_types?.filter(
      (item) => item?.type === typeNumber
    );
    setIsEdit(true);
    let name = ticketType[0]?.name;
    setTicketType(name);
    setUpdateTicketType({ ticket: ticketType[0], index: index });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-1">
          <i
            className="bi bi-arrow-left-circle h1"
            onClick={goBack}
            role="button"
          ></i>
        </div>
        <div className="col-9">
          <h1 className="text-left">
            <b>{t("title")}</b>
          </h1>
        </div>
        <hr className="my-3" />
      </div>
      <div className="row">
        <div className="col-md-6 col-12 py-2">
          <div>
            <div class="position-relative h-100 w-100">
              {imageLoader ? <div class="spinner-border text-secondary position-absolute top-50 start-50" role="status">
                <span class="visually-hidden">Loading...</span>
              </div> : <img
                alt="event-img"
                src={event?.cover_photo ? event?.cover_photo : `${imglogo}`}
                className="img-fluid img-thumbnail w-100 h-100"
              />}

              <div className="d-flex text-secondary px-4 py-1 fs-4 fw-bold position-absolute top-50 start-50  translate-middle">
                {!imageLoader && <form>
                  {event?.cover_photo ? (
                    <label htmlFor="fileInput" className="mx-1 btn btn-light">
                      <i class="bi bi-pencil"></i>
                    </label>
                  ) : (
                    <label htmlFor="fileInput" className="mx-1 btn btn-light">
                      +
                    </label>
                  )}
                  {event?.cover_photo && (
                    <label
                      className="mx-1 btn btn-light"
                      htmlFor="fileInputDelete"
                    >
                      <i class="bi bi-trash"></i>
                    </label>
                  )}
                </form>}

              </div>
              <input
                type="file"
                id="fileInput"
                className="d-none"
                onChange={handleImage}
              />
              <input
                type="button"
                id="fileInputDelete"
                className="d-none"
                onClick={deleteImage}
              />
            </div>
            <p className="text-danger">{errors && errors?.cover_photo}</p>
          </div>
        </div>
        <div className="col-md-6 col-12 py-2">
          <input
            type="text"
            value={event?.title}
            required
            onChange={updateEvent}
            className="form-control py-2 bg-transparent border-secondary-subtle"
            placeholder={t("placeholder.eventTitle")}
            name="title"
          />
          <p className="text-danger mb-0">{errors && errors?.title}</p>
          <br />
          <div>
            <textarea
              rows={7}
              value={event?.description}
              onChange={updateEvent}
              className="form-control py-2 bg-transparent border-secondary-subtle h-100"
              placeholder={t("placeholder.eventdescription")}
              name="description"
            />
            <p className="text-danger mb-0">
              {errors && errors?.description}
            </p>
          </div>
        </div>
        <div className="col-12 my-md-2">
          <input
            type="text"
            value={event?.address}
            onChange={updateEvent}
            className="form-control py-2 bg-transparent border-secondary-subtle"
            placeholder={t("placeholder.eventAddress")}
            name="address"
          />
          <p className="text-danger mb-0">{errors && errors?.address}</p>
        </div>
      </div>
      <div className="row">
        <p className="text-dark fw-bold">{t("addTicketType")}</p>
        <div className="d-flex mb-3">
          <div className="col-4">
            <input
              className="form-control"
              value={ticketType}
              placeholder={t("ticketName")}
              onChange={(e) => setTicketType(e.target.value)}
            />
          </div>
          <button
            className="btn btn-outline-secondary fw-bold mx-2"
            onClick={handleTicketTypes}
          >
            {isEdit ? t("update") : t("add")}
          </button>
        </div>
        {errors?.ticketName && <p className="text-danger">{errors?.ticketName}</p>}

        {Array.isArray(event?.ticket_types) &&
          event?.ticket_types.map((item, i) => {
            const index = isDeleteTyp0 ? i + 1 : i;
            return (
              <div className="col-md-4 col-12 my-3" key={item?.name}>
                <label className="fw-bold mb-2">
                  {item?.type} {item?.name}
                </label>
                <div className="d-flex">
                  <div className="input-group">
                    <input
                      type={Number}
                      name={item?.name}
                      value={item?.value}
                      onChange={(e) => handleTicktPrices(e, item, index)}
                      placeholder={t("ticketPrice")}
                      className="form-control me-2 bg-transparent rounded-0 rounded-3"
                    />
                    <span
                      className="input-group-text  position-absolute end-0 px-0 me-2"
                      id="basic-addon2"
                    >
                      <img
                        className="rounded flag-logo"
                        src="/static/media/norway.4ddaead76972f63cfc0133f71db8637c.svg"
                        alt=""
                      />
                      NOK
                    </span>
                  </div>
                  <div className="d-flex ">
                    <i
                      className="bi bi-pencil d-block mx-2 cursor fs-5"
                      onClick={() => handleEditTicketType(item?.type, index)}
                    ></i>
                    <i
                      className="bi bi-trash d-block cursor fs-5"
                      onClick={() =>
                        handleDeleteTicketType(
                          isDeleteTyp0 ? index - 1 : index,
                          item
                        )
                      }
                    ></i>
                  </div>
                </div>
              </div>
            );
          })}

        {errors?.ticket_types && <p className="text-danger">{errors?.ticket_types}</p>}
      </div>
      <div className="row">
        <div className="mb-5">
          {ticketCountAndScheduleRow()}
          {ButtonsRowHtml()}
        </div>
      </div>
      <ConfirmDelete handleAction={action?.actionName} action={action} />
    </div>
  );
};
export default AddEvent;