import React from "react";
import { BrowserRouter, Switch, Route, Routes, Link } from "react-router-dom";
import { Header, Footer } from "./components/shared";
import { ContextProvider } from "MyContext";
import Home from "./views/Home/Home";
import EventDetail from "./views/EventDetail";
import Checkout from "./views/Checkout";
import About from "./views/pages/About";
import Terms from "./views/pages/Terms";
import Login from "./views/Auth/Login";
import Signup from "./views/Auth/Signup";
import PasswordReset from "./views/Auth/PasswordReset";
import Profile from "./views/Profile/Index";
import Tickets from "./views/Tickets";
import Return from "./views/Return";
import AddEvent from "views/Admin/AddEvent";
import Admin from "./views/Admin";
import NotFound from "views/pages/NotFound";
import RequiredContext from "RequiredContext";
import AddCoupon from "views/Admin/AddCoupon";
import Stepper from "views/Admin/Stepper";
import Dashboard from "views/Dashboard/Index";
import Tono from "views/pages/Tono";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div
        className="container-fluid"
      >
        <ContextProvider>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            {/* <Route path="/tono/:id" element={<Tono />} /> */}

            <Route path="/events/addEvent" element={<Stepper />} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            {/* <Route path="/eventEdit/:id" element={<AddEvent />} /> */}
            <Route path="/eventEdit/:id" element={<Stepper />} />
            <Route path="/event/:id" element={<EventDetail />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/return" element={<Return />} />
            <Route
              path="/events"
              element={
                <RequiredContext>
                  <Admin />
                </RequiredContext>
              }
            />
            <Route
              path="/coupon"
              element={
                <RequiredContext>
                  <AddCoupon />
                </RequiredContext>
              }
            />
            <Route
              path="/tickets"
              element={
                <RequiredContext>
                  <Tickets />
                </RequiredContext>
              }
            />
            <Route
              path="/profile"
              element={
                <RequiredContext>
                  <Profile />
                </RequiredContext>
              }
            ></Route>
            <Route
              path="/dashboard"
              element={
                <RequiredContext>
                  {" "}
                  <Dashboard />
                </RequiredContext>
              }
            ></Route>
            <Route
              path="/tono/:id"
              element={
                <RequiredContext>
                  <Tono />
                </RequiredContext>
              }
            ></Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </ContextProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
