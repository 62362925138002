import { useTranslation } from "react-i18next";

export const useSignUpValidation = () => {
    const { t } = useTranslation("signup");

    //method to vailidate the required input fields on entering input field
    const SignUpValidationFields = (fieldName, value) => {
        switch (fieldName) {
            case "email":
                return validateEmail(value);
            case "password":
                return validatePassword(value);
            case "confirmPassword":
                return validatePassword(value);
            case "username":
                return validateUserName(value);
            case "firstname":
                return validateFirstName(value);
            case "lastname":
                return validateLastName(value);
            default:
                return "";
        }
    };

    //validate the email
    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let isValid = regex.test(email);
        if (isValid) {
            return "";
        } else {
            // return "Please Enter Valid Email";
            return t("validations.email")
        }
    }

    //validate username
    const validateUserName = (value) => {
        if (!value?.trim()) {
            return t("validations.username")
        } else {
            return "";
        }
    }

    //validate firstname
    const validateFirstName = (value) => {
        if (!value?.trim()) {
            return t("validations.firstname")
        } else {
            return "";
        }
    }
    //validate lastname
    const validateLastName = (value) => {
        if (!value?.trim()) {
            return t("validations.lastname")
        } else {
            return "";
        }
    }
    
    //validate password
    const validatePassword = (value) => {
        let passworderror = ""
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&^]{8,15}$/;
        if (!regex.test(value)) {
            passworderror = t("validations.password")
        }
        else {
            passworderror = ""
        }

        return passworderror
    };

    //validate signup form
    const signUpForm = (formDate) => {
        const newErrors = {
            username: validateUserName(formDate?.username),
            firstname: validateFirstName(formDate?.firstname),
            lastname: validateLastName(formDate?.lastname),
            email: validateEmail(formDate?.email),
            password: validatePassword(formDate?.password),
            confirmPassword: validatePassword(formDate?.confirmPassword)
        }

        if (newErrors?.username ||
            newErrors?.firstname ||
            newErrors?.lastname ||
            newErrors?.email ||
            newErrors?.password ||
            newErrors?.confirmPassword
        ) {
            return { err: newErrors }
        } else {
            return { err: "" }
        }
    }

    return { signUpForm, SignUpValidationFields }
}