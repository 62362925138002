import React, { useEffect, useRef, useState } from "react";
import BankEdit from "./EditBank";
import { api } from "services";
import Spinner from "components/shared/Spinner";

const Bank = () => {
  const [account, setAccount] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [loading, setLoading] = useState(true);

  const childref = useRef();

  //method to fetch the bank details based on current logged in organizer Id
  const fetchBankDetails = async () => {
    try {
      const res = await api.getBankDetails();
      setAccount(res);
    } catch (error) {
      console.log("fetchBankDetails ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBankDetails();
  }, [isEdit, isAdd]);

  //Bank Profile Component
  const BankProfile = () => (
    <div className="col-12">
      <table class="table table-borderless">
        <tbody>
          <tr>
            <td className="fw-bolder" aria-placeholder="name">
              Account Name
            </td>
            <td>{account?.get("accountName")}</td>
          </tr>
          <tr>
            <td className="fw-bolder">Account Number</td>
            <td>{account?.get("accountNumber")}</td>
          </tr>
          <tr>
            <td className="fw-bolder">Account Type</td>
            <td>{account?.get("accountType")}</td>
          </tr>
          <tr>
            {account?.get("location") == "India" ? (
              <td className="fw-bolder">IFSC Code</td>
            ) : (
              <td className="fw-bolder">SWIFT Code</td>
            )}
            <td>{account?.get("code")}</td>
          </tr>
          <tr>
            <td className="fw-bolder">Bank Location</td>
            <td>{account?.get("location")}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  //method to call handSubmit method in child component (EditBank.js file)
  const handleAddBankAccount = () => {
    if (childref?.current) {
      childref?.current?.handleSubmit();
    }
    setIsAdd(false);
    // window.location.reload();
  };

  //method to call the handleUpdate method in child component (EditBank.js file)
  const handleUpdateBankAccount = () => {
    if (childref?.current) {
      childref?.current?.handleUpdate();
    }
    setIsEdit(false);
    // window.location.reload();
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center align-content-center">
        <Spinner />
      </div>
    );

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {account ? (
            //Updating Bank Account
            <div>
              <div className="d-flex justify-content-between">
                {isEdit ? (
                  <div
                    className="d-inline cursor d-flex"
                    onClick={() => setIsEdit(false)}
                  >
                    <i class="bi bi-arrow-left-circle mx-2 fs-2"></i>
                    <h2 className="fw-bolder">Update Bank Details</h2>
                  </div>
                ) : (
                  <h2 className="fw-bolder">Bank Account</h2>
                )}
                {isEdit ? (
                  <button
                    className="btn btn-secondary"
                    onClick={handleUpdateBankAccount}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn btn-secondary"
                    onClick={() => setIsEdit(true)}
                  >
                    Edit
                  </button>
                )}
              </div>

              {isEdit ? (
                <BankEdit
                  ref={childref}
                  isEdit={isEdit}
                />
              ) : (
                <BankProfile />
              )}
            </div>
          ) : (
            //Adding Bank Account
            <div>
              <div className="d-flex justify-content-between">
                {isAdd ? (
                  <>
                    <div
                      className="d-inline cursor d-flex"
                      onClick={() => setIsAdd(false)}
                    >
                      <i class="bi bi-arrow-left-circle mx-2 fs-2"></i>
                      <h2 className="fw-bolder mx-3">Bank Account</h2>
                    </div>

                    <button
                      className="btn btn-secondary"
                      onClick={handleAddBankAccount}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <>
                    <h2 className="fw-bolder">Bank Account</h2>
                    <button
                      className="btn btn-secondary"
                      onClick={() => setIsAdd(true)}
                    >
                      Add
                    </button>
                  </>
                )}
              </div>
              {isAdd ? <BankEdit ref={childref} /> : <BankProfile />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Bank;
