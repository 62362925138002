import Spinner from "components/shared/Spinner";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { api, auth } from "services";
import { currencyFormatter } from "utils";

const Payment = () => {
  const [bookings, setBookings] = useState([]);
  const [data, setData] = useState([]);
  const [currentpagenumber, setCurentPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [paymentType, setPaymentType] = useState("complete");
  const [sorting, setSorting] = useState(true);
  const [amountSorting, setAmoutSorting] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const [totalPages, setTotalPages] = useState(1);
  const [filteredAndSorted, setFilteredAndSorted] = useState([]);
  const { t } = useTranslation("dashboard");
  const step_number = 10;

  const sortingAmount = () => {
    setAmoutSorting(!amountSorting);
    const filtered = filterPayments(bookings);
    const publishedData = filtered?.sort((a, b) => {
      const amountA = a?.get("amount");
      const amountB = b?.get("amount");
      return !amountSorting ? amountA - amountB : amountB - amountA;
    });
    setData(publishedData?.slice(0, step_number));
    setFilteredAndSorted(publishedData);
    setCurentPageNumber(1);
    return publishedData;
  };

  const sortingDate = (bookings) => {
    const filtered = filterPayments(bookings);
    const sortedData = filtered?.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return !sorting ? dateA - dateB : dateB - dateA;
    });
    setFilteredAndSorted(sortedData);
    return sortedData;
  };

  const filterPayments = (payments) => {
    let filteredPayments = payments?.filter((item) => {
      return item?.get("status") === paymentType;
    });
    return filteredPayments;
  };

  const fetchBookings = async () => {
    try {
      const user = auth.fetchUser();

      const userRole = await api.getCurrentUserRoles();

      if (userRole === "admin") {
        const bookings = await api.getAllBookings();
        console.log("bookings ", bookings);
        setBookings(bookings); // All bookings
        setData(sortingDate(bookings)); // Filtered and sorted bookings
      } else {
        const bookings = await api.getOrganizerAllBookings(user?.id);
        setBookings(bookings); // All bookings
        setData(sortingDate(bookings)); // Filtered and sorted bookings
      }
    } catch (error) {
      console.log("fetchBookings error ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, [paymentType]);

  // Reapply filters and sorting when payment type or sorting changes
  useEffect(() => {
    const filteredData = filterPayments(bookings);
    setData(sortingDate(filteredData).slice(0, step_number));
    setCurentPageNumber(1); // Reset to the first page after filtering
  }, [paymentType, sorting]);

  const handleSearch = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    if (lowercasedSearchTerm.trim() === "") {
      const filtered = filterPayments(bookings);
      setData(sortingDate(filtered).slice(0, step_number));
    } else {
      const filteredResults = bookings.filter((item) => {
        const eventTitle =
          item?.get("eventId")?.get("title")?.toLowerCase() || "";
        const amount =
          currencyFormatter(item?.get("amount"))?.toLowerCase() || "";
        const date =
          new Date(item?.get("createdAt").toString())
            .toUTCString()
            .toLowerCase() || "";
        const username =
          item?.get("userId")?.get("username")?.toLowerCase() || "";

        // Check if search term exists in event title, amount, date, or username
        return (
          eventTitle.includes(lowercasedSearchTerm) ||
          amount.includes(lowercasedSearchTerm) ||
          date.includes(lowercasedSearchTerm) ||
          username.includes(lowercasedSearchTerm)
        );
      });

      setData(sortingDate(filteredResults).slice(0, step_number));
    }
    setCurentPageNumber(1);
  };

  useEffect(() => {
    handleSearch();
    setCurentPageNumber(1);
  }, [searchTerm]);

  const handlePrev = () => {
    if (currentpagenumber <= 1) return;
    setCurentPageNumber((prev) => prev - 1);
  };

  const handleNext = () => {
    // let totalPages = Math.ceil(bookings.length / step_number);
    // console.log(totalPages);

    if (currentpagenumber < totalPages) {
      setCurentPageNumber((prev) => prev + 1);
    }
  };

  // Pagination logic applied to the filtered data
  useEffect(() => {
    // const filteredData = filterPayments(bookings);
    // const sortedData = sortingDate(filteredData);
    setTotalPages(Math.ceil(filteredAndSorted.length / step_number));
    const start = (currentpagenumber - 1) * step_number;
    const end = currentpagenumber * step_number;
    setData(filteredAndSorted.slice(start, end));
  }, [currentpagenumber, paymentType, bookings]);

  const handlePageNumber = (value) => {
    // let totalPages = Math.ceil(data.length / step_number);
    if (value <= totalPages && value >= 1) {
      setCurentPageNumber(value);
    }
  };

  if (loading) return <Spinner />;

  return (
    <div className="p-3 bg-white border rounded-3 mt-2 shadow-sm mb-5">
      <div className="d-flex justify-content-between">
        <h3 className="text-center text-md-start">{t("payment.title")}</h3>
        <div className="d-flex w-100 flex-start justify-content-end">
          <input
            type="text"
            className="form-control form-control-sm ms-3 w-25"
            placeholder={t("payment.search")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <select
            className="form-select form-select-sm ms-3"
            style={{ width: "100px" }}
            aria-label="Filter by payment status"
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
          >
            <option value="complete">{t("payment.status.success")}</option>
            <option value="open">{t("payment.status.failed")}</option>
          </select>
        </div>
      </div>
      <div className="table-responsive  rounded-3  mt-4 mb-3">
        <table className="table rounded-2">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t("payment.userName")}</th>
              <th scope="col">{t("payment.eventName")}</th>
              <th
                scope="col"
                onClick={() => sortingAmount()}
              // className="d-flex cursor align-items-center"
              >
                {t("payment.amount")}
                {amountSorting ? (
                  <i className="bi bi-caret-down-fill ms-1"></i>
                ) : (
                  <i className="bi bi-caret-up-fill ms-1"></i>
                )}
              </th>
              <th
                scope="col"
                onClick={() => setSorting(!sorting)}
              // className="d-flex cursor align-items-center"
              >
                {t("payment.date")}
                {sorting ? (
                  <i className="bi bi-caret-down-fill ms-1"></i>
                ) : (
                  <i className="bi bi-caret-up-fill ms-1"></i>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {filterPayments(bookings).length > 0 ? (
              data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <th scope="col">
                      {(currentpagenumber - 1) * step_number + index + 1}
                    </th>
                    <td scope="col">
                      {item?.get("userId")?.get("username") || "Not Found"}
                    </td>
                    <td scope="col">{item?.get("eventId")?.get("title")}</td>
                    <td scope="col">
                      <p
                        className={
                          item?.get("status") === "complete"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {currencyFormatter(item?.get("amount"))}
                      </p>
                    </td>
                    <td scope="col">
                      {new Date(
                        item?.get("createdAt").toString()
                      ).toUTCString()}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  {t("payment.noPayment")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={`page-item ${currentpagenumber === 1 ? "disabled" : ""
                  } cursor`}
              >
                <a
                  className="page-link text-dark"
                  onClick={handlePrev}
                  aria-disabled={currentpagenumber === 1}
                >
                  {t("buttons.prev")}
                </a>
              </li>

              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (item) => (
                  <li
                    key={item}
                    className={`page-item ${currentpagenumber === item ? "active" : ""
                      } `}
                  >
                    <a
                      className={`page-link cursor ${currentpagenumber === item ? "text-white" : "text-dark"}`}
                      onClick={() => handlePageNumber(item)}
                    >
                      {item}
                    </a>
                  </li>
                )
              )}
              <li
                className={`page-item ${currentpagenumber === totalPages ? "disabled" : ""
                  } cursor`}
              >
                <a
                  className="page-link text-dark"
                  onClick={handleNext}
                  aria-disabled={currentpagenumber === totalPages}
                >
                  {t("buttons.next")}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Payment;