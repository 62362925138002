import React, { useEffect, useState } from "react";
import { signupUser } from "services/auth.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { api, auth } from "services";
import { useSignUpValidation } from "views/validation/Signup";

const Signup = () => {
  const [success, setSuccess] = useState("");
  const [user, setUser] = useState("");
  const [errors, setErrors] = useState({});
  const [userDetails, setUserDetails] = useState();
  const [signUpError, setSignUpError] = useState("");
  const { t } = useTranslation("signup");
  const { signUpForm, SignUpValidationFields } = useSignUpValidation();

  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.fetchUser();
    if (user) {
      setUser(user);
    }
  }, [success]);

  // Revalidate fields when the language changes
  useEffect(() => {
    if (userDetails) {
        const { err } = signUpForm(userDetails);
        setErrors(err);
    }
}, [t]); // Run this whenever the translation function changes


  // Updated password validation function
  const validatePassword = (value) => {
    let passworderror = "";
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&^]{8,15}$/;
    if (!regex.test(value)) {
      passworderror = t("validations.password")
      // passworderror =
      //   "Password must be atleast 8 characters long, include at least one uppercase letter, one lowercase letter, one digit, and one special character.";
    } else {
      passworderror = "";
    }
    return passworderror;
  };

  //handle show hide eye icon
  const handleShowHidePassword = (eleId) => {
    const ele1 = document.getElementById(eleId);
    let node1 = ele1.childNodes[0];
    let node2 = ele1.childNodes[1];

    if (node1.type === "text") {
      node1.type = "password";
      node2.innerHTML = '<i class="bi bi-eye-slash"></i>';
    } else {
      node1.type = "text";
      node2.innerHTML = '<i class="bi bi-eye"></i>';
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    // const { err } = signUpForm(userDetails);
    const { err } = signUpForm(userDetails);
    if (!err) {
      if (userDetails?.password !== userDetails?.confirmPassword) {
        setErrors((prev) => ({
          ...prev,
          ["confirmPassword"]: t("message.confirmPassword"),
        }));
        return;
      }
      try {
        // const res1 = await login(username,password);
        // setSuccess(
        //   "Account created successfully. Please check your email to verify."
        // );

        const res = await signupUser(userDetails);
        if (res) {
          setSignUpError("");
          setSuccess((prevState) => t("message.loginsuccess"));
          // setTimeout(() => {
          //   navigate("/");
          // }, "2000");
          // handleNavigate();
        }
      } catch (err) {
        setSignUpError(err);
      }
    } else {
      setErrors(err);
      // setError("");
      // setSuccess("");
    }
  };

  const handleUpdateUser = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({ ...prevState, [name]: value }));
    if (name === "password" || name === "confirmPassword") {
      setErrors((prev) => ({ ...prev, [name]: validatePassword(value) }));
    } else {
      // const err = ValidateField(name, value);
      const err = SignUpValidationFields(name, value);
      setErrors((prev) => ({ ...prev, [name]: err }));
    }
  };

  const handleNavigate = async () => {
    let role = await api.getCurrentUserRoles();
    if (role == "user") {
      const bookingCart = localStorage.getItem("bookingCart");
      if (bookingCart) {
        const { eventId } = JSON.parse(bookingCart);
        navigate(`/event/${eventId}`);
      } else {
        navigate("/");
      }
    } else if (role == "admin" || role == "organizer") {
      navigate("/dashboard");
    }
    // window.location.reload();
  };

  if (user) {
    handleNavigate();
    return;
  }
  return (
    <div className="container py-5 my-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="text-center">
            <h2 className="py-3">{t("title")}</h2>
          </div>
          {/* Form */}
          <form>
            <div className="mb-3">
              <input
                type="text"
                name="username"
                value={userDetails?.username}
                onChange={handleUpdateUser}
                className="form-control py-3"
                placeholder={t("placeholder.username")}
              />
              <p className="text-danger">{errors && errors?.username}</p>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="text"
                  name="firstname"
                  value={userDetails?.firstname}
                  onChange={handleUpdateUser}
                  className="form-control py-3"
                  placeholder={t("placeholder.firstname")}
                />
                <p className="text-danger">{errors && errors?.firstname}</p>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="text"
                  name="lastname"
                  value={userDetails?.lastname}
                  onChange={handleUpdateUser}
                  className="form-control py-3"
                  placeholder={t("placeholder.lastname")}
                />
                <p className="text-danger">{errors && errors?.lastname}</p>
              </div>
            </div>
            <div className="mb-3">
              <input
                type="email"
                name="email"
                value={userDetails?.email}
                onChange={handleUpdateUser}
                className="form-control py-3"
                placeholder={t("placeholder.email")}
              />
              <p className="text-danger">{errors && errors?.email}</p>
            </div>
            <div id="mypassword" className="position-relative">
              <input
                type="password"
                id="password"
                name="password"
                value={userDetails?.password}
                onChange={handleUpdateUser}
                className="form-control py-3 my-3"
                placeholder={t("placeholder.password")}
              />
              <span
                className="position-absolute top-0  end-0 p-3"
                id="eye2"
                style={{ cursor: "pointer" }}
                onClick={() => handleShowHidePassword("mypassword")}
              >
                <i class="bi bi-eye-slash"></i>
              </span>
              <p className="text-danger">{errors && errors?.password}</p>
            </div>
            <div className="mb-3">
              <input
                type="confirmpassword"
                name="confirmPassword"
                value={userDetails?.confirmPassword}
                onChange={handleUpdateUser}
                className="form-control py-3"
                placeholder={t("placeholder.confirmpassword")}
              />
            </div>
            {errors && <p className="text-danger">{errors?.confirmPassword}</p>}
            {signUpError && <p className="text-danger">{signUpError}</p>}
            {success && <p className="text-success">{success}</p>}
            <div className="d-grid">
              <button
                type="submit"
                onClick={handleSignup}
                className="py-3 btn btn-outline-secondary btn-block fw-bold"
              >
                {t("signup")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
