import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    backgroundColor: "#fdfaf3",
  },
  section: {
    marginBottom: 10,
    padding: 10,
    border: "1px solid #eaeaea",
  },
  title: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: "bold",
  },
  text: {
    fontSize: 10,
    marginBottom: 5,
  },
});

const MyDocument = ({ eventDetails }) => {
  console.log("eventDetails ", eventDetails);
  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.title}>Event Information</Text>
        <Text style={styles.text}>Show Name: {eventDetails.showName}</Text>
        <Text style={styles.text}>Artist: {eventDetails.artist}</Text>
        <Text style={styles.text}>
          Event Type: {eventDetails.eventType}
        </Text>
        <Text style={styles.text}>
          Event Location {eventDetails?.venueLocation}
        </Text>
        <Text style={styles.text}>
          Start Date: {eventDetails.startDate.toString()}
        </Text>
        <Text style={styles.text}>End Date: {eventDetails.endDate}</Text>
        <Text style={styles.text}>
          Number of Shows: {eventDetails.numberOfShows}
        </Text>
        <Text style={styles.text}>
          No Of Audience: {eventDetails?.audience}
        </Text>
        <Text style={styles.text}>
          Gross Revenue: {eventDetails?.grossRevenue}
        </Text>
        <Text style={styles.text}>Notes: {eventDetails?.notes}</Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>Customer Information</Text>
        <Text style={styles.text}>I am : {eventDetails?.user}</Text>
        <Text style={styles.text}>
          Contact Name: {eventDetails.contactName}
        </Text>
        <Text style={styles.text}>
          Contact Phone: {eventDetails.contactPhone}
        </Text>
        <Text style={styles.text}>
          Custom Email: {eventDetails.customEmail}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>Venue Information</Text>
        <Text style={styles.text}>
          Venue Name: {eventDetails.venueName}
        </Text>
        <Text style={styles.text}>
          Venue Location: {eventDetails.venueLocation}
        </Text>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>Organization Information</Text>
        <Text style={styles.text}>
          Company Name: {eventDetails.companyName}
        </Text>
        <Text style={styles.text}>
          Operating Company: {eventDetails.operatingCompany}
        </Text>
        <Text style={styles.text}>Website: {eventDetails.website}</Text>
        <Text style={styles.text}>
          Organization Number: {eventDetails.organizationNumber}
        </Text>
        <Text style={styles.text}>Website: {eventDetails.website}</Text>
      </View>

      <view style={styles.section}>
        <Text style={styles.title}>Billing Address</Text>
        <Text style={styles.text}>
          Invoice Address : {eventDetails?.invoiceAddress}
        </Text>
        <Text style={styles.text}>
          P.O. Box {eventDetails?.invoicePoBox}
        </Text>
        <Text style={styles.text}>
          Postal Code{eventDetails?.invoicePostalCode}
        </Text>
        <Text style={styles.text}>
          Postal area{eventDetails?.invoicePostalArea}
        </Text>
        <Text style={styles.text}>
          Invoice notes{eventDetails?.invoiceNote}
        </Text>
        <Text style={styles.text}>
          Invoice Delivered : {eventDetails?.invoiceDeliver}
        </Text>
      </view>

      <view style={styles.section}>
        <Text style={styles.title}>Postal Address Information</Text>
        <Text style={styles.text}>
          Postal address (Street/road) : {eventDetails?.postalAddress}
        </Text>
        <Text style={styles.text}>
          P.O Box (Postal address) : {eventDetails?.postalPoBox}
        </Text>
        <Text style={styles.text}>
          ZIP code (Postal address) : {eventDetails?.postalZipCode}
        </Text>
        <Text style={styles.text}>
          Postal area (Postal address) : {eventDetails?.postalArea}
        </Text>
      </view>
      <view style={styles.section}>
        <Text style={styles.title}>Office Address Information</Text>
        <Text style={styles.text}>
          Office address (Street/road) : {eventDetails?.officeAddress}
        </Text>
        <Text style={styles.text}>
          Zip code (Office address ) : {eventDetails?.officeZipCode}
        </Text>
        <Text style={styles.text}>
          City (Office address ) : {eventDetails?.officeCity}
        </Text>
      </view>
    </Page>
  </Document>
  );
};

export default MyDocument;