import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOragnizerInformation, sendEmailStatus } from "services/api.service";
import { ValidateField, validateTonoForm } from "views/validation/Validations";
import { pdf } from "@react-pdf/renderer";
import MyDocument from "./MyDocument";
import DatePicker from "react-datepicker";
import { TonoValidations } from "views/validation/Tono";

const Tono = () => {
  const [event, setEvent] = useState({});
  const [showOtherDescription, setShowOtherDescription] = useState(false);
  const [iscustomerPage, setIsCustomerPage] = useState(false);
  const [isBusinessman, setIsBusinessman] = useState(false);
  const [postalAddress, setPostalAddress] = useState(true);
  const [officeAddress, setOfficeAddress] = useState(true);
  const [showInvoiceEmail, setShowInvoiceEmail] = useState(false);
  const [showCustomEmail, setShowCustomEmail] = useState(false);
  const [errors, setErrors] = useState({});
  // const {ValidateField,validateTonoForm} = TonoValidations();

  const { id } = useParams();
  const navigate = useNavigate();

  //intially fetching the organizer event information and autofilling the form
  const fetchUserDetails = async () => {
    try {
      const res = await fetchOragnizerInformation(id);
      // console.log("res ", res);
      let event_organizer_information = {
        venueLocation: res?.event_address || "",
        showName: res?.event_name || "",
        contactName: res?.organizer_name || "",
        contactPhone: res?.organizer_phone || "",
        invoiceAddress: res?.organizer_address || "",
        contactEmail: res?.organizer_email || "",
        startDate: res?.event_start_date || "",
        endDate: res?.event_start_date || "",
        audience: res?.audient_count || 0,
        numberOfShows: 1
      };
      setEvent((prevState) => ({
        ...prevState,
        ...event_organizer_information,
      }));
    } catch (error) {
      console.log("error ", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
    // setEvent((prevState) => ({
    //   ...prevState,
    //   invoicePoBox: "",
    //   invoicePostalArea: "",
    //   invoicePostalCode: "",
    // }));
  }, []);

  //intially office address section auto filled  with invoice data if its checked
  useEffect(() => {
    // fetchUserDetails();
    // console.log("officeAddress ",officeAddress)
    if (officeAddress) {
      let officeAddressObj = {
        officeAddress: event?.invoiceAddress,
        officeZipCode: event?.invoicePostalCode,
        officeCity: event?.invoicePostalArea,
      };
      setEvent((prevState) => ({
        ...prevState,
        ...officeAddressObj,
      }));
    } else {
      let officeAddressObj = {
        officeAddress: "",
        officeZipCode: "",
        officeCity: "",
      };
      setEvent((prevState) => ({
        ...prevState,
        ...officeAddressObj,
      }));
    }
  }, [officeAddress]);

  //intially postAddress section auto filled with invoice data if its checked else dont fill
  useEffect(() => {
    // console.log("postalAddress ",postalAddress)
    if (postalAddress) {
      let postalAddressObj = {
        postalAddress: event?.invoiceAddress,
        postalPoBox: event?.invoicePoBox,
        postalZipCode: event?.invoicePostalCode,
        postalArea: event?.invoicePostalArea,
      };
      setEvent((prevState) => ({
        ...prevState,
        ...postalAddressObj,
      }));
    } else {
      let postalAddressObj = {
        postalAddress: "",
        postalPoBox: "",
        postalZipCode: "",
        postalArea: "",
      };
      setEvent((prevState) => ({
        ...prevState,
        ...postalAddressObj,
      }));
    }
  }, [postalAddress]);

  //custom email input field clear if its unchecked
  useEffect(() => {
    if (!showCustomEmail) {
      setEvent((prevState) => ({
        ...prevState,
        customEmail: "",
      }));
    }
  }, [showCustomEmail]);

  //updating all form data in event state
  const handleEvent = (e) => {
    const { name, value } = e.target;

    //handle validation checking
    const err = ValidateField(name, value);
    // console.log("err ", err);

    setErrors((prev) => ({
      ...prev,
      [name]: err || null,
    }));

    //updating event state
    setEvent((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    //custom logic for Event Type input field
    if (name === "eventType" && value === "Other") {
      setShowOtherDescription(true);
    } else {
      if (
        [
          "Festival",
          "Exhibition",
          "Concert/Event",
          "Revue/Cabaret",
          "Recorded Background Music",
        ].includes(value)
      ) {
        setShowOtherDescription(false);
      }
    }

    //custom logic for Invoice Deliver Input field
    if (name === "invoiceDeliver") {
      if (value === "E-mail") {
        setShowInvoiceEmail(true);
      }
      if (value === event?.invoiceAddress) {
        setEvent((prevState) => ({
          ...prevState,
          [e.target.name]: event?.invoiceAddress,
        }));
        setShowInvoiceEmail(false);
      }
      if (value === "EHF") {
        setShowInvoiceEmail(false);
      }
    }

    //handle postal address input field if its checked
    if (postalAddress) {
      let postalAddressObj = {
        postalAddress: event?.invoiceAddress,
        postalPoBox: event?.invoicePoBox,
        postalZipCode: event?.invoicePostalCode,
        postalArea: event?.invoicePostalArea,
      };
      setEvent((prevState) => ({
        ...prevState,
        ...postalAddressObj,
      }));
    }
    //handle Office address if its checked
    if (officeAddress) {
      let postalAddressObj = {
        officeAddress: event?.invoiceAddress,
        officeZipCode: event?.invoicePostalCode,
        officeCity: event?.invoicePostalArea,
      };
      setEvent((prevState) => ({
        ...prevState,
        ...postalAddressObj,
      }));
    }
  };

  //method to check form validations
  const handleFormErrorCheck = () => {
    const { err } = validateTonoForm(event);
    // console.log("err ",err)
    setErrors(err);
  };
  //handle Form Submit
  const handleTonoFormSubmit = async (e) => {
    e.preventDefault();
    const { err } = validateTonoForm(event);
    // console.log("err ",err)
    setErrors(err);

    if (!err) {
      console.log("no error", err);
      // method to update email status
      try {
        const res = await sendEmailStatus(id);
        // console.log("response is ", res);
        if (res) {
          navigate("/dashboard");
        }
      } catch (error) {
        console.log("Tono Form email Status Update Error ", error);
      }
    }
  };

  //handle Form Download
  const handleFormDownload = async (e) => {
    e.preventDefault();
    if (!event) {
      console.error("Invalid event data", event);
      return;
    }

    console.log("event ", event)
    const updatedevent = event;
    updatedevent.startDate = String(event?.startDate);
    updatedevent.endDate = String(event?.endDate)
    console.log("updatedevent ", updatedevent)
    // // Generate PDF and get the Blob
    const doc = <MyDocument eventDetails={updatedevent} />;
    const blob = await pdf(doc)?.toBlob();

    // Create a temporary URL from the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary <a> element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "Event.pdf";
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the temporary <a> element and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  //adding the css to event and customer tabs dynammically
  const handleNext = () => {
    setIsCustomerPage(true);
    let eventEle = document.getElementById("eventinformation");
    let customerEle = document.getElementById("customerinformation");
    eventEle.classList.remove("active");
    customerEle.classList.add("active");
  };

  //adding the css to event and customer tabs dynammically
  const handlePrevious = () => {
    setIsCustomerPage(false);
    let eventEle = document.getElementById("eventinformation");
    let customerEle = document.getElementById("customerinformation");
    customerEle.classList.remove("active");
    eventEle.classList.add("active");
  };

  //handle fileinuput field
  const handleFile = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setEvent((prev) => ({
        ...prev,
        file: selectedFile,
      }));
    }
  };

  //method to delete the input file pdf
  const handleDeleteFile = () => {
    setEvent((prev) => ({
      ...prev,
      file: "",
    }));
  };

  //Event Information Form
  const EventInformation = () => (
    <div className="container">
      <div className="row">
        <div className="col-12  my-2">
          <h3 className="fw-bolder mt-5">Information about your event</h3>
          <hr className="mb-1" />
          {/* Event Type */}
          <div className="my-2">
            <label htmlFor="" className="mb-2">
              Event Type <span className="text-danger">*</span>
            </label>
            <select
              name="eventType"
              onClick={handleEvent}
              className="form-select"
              id="inputGroupSelect01"
            >
              <option selected value="" disabled>
                Please Select Event Type
              </option>
              <option
                selected={event?.eventType === "Concert/Event"}
                value="Concert/Event"
              >
                Concert/Event
              </option>
              <option
                selected={event?.eventType === "Festival"}
                value="Festival"
              >
                Festival
              </option>
              <option
                selected={event?.eventType === "Exhibition"}
                value="Exhibition"
              >
                Exhibition
              </option>
              <option
                selected={event?.eventType === "Revue/Cabaret"}
                value="Revue/Cabaret"
              >
                Revue/Cabaret
              </option>
              <option
                selected={event?.eventType === "Recorded Background Music"}
                value="Recorded Background Music"
              >
                Recorded Background Music
              </option>
              <option value="Other">Other</option>
            </select>
            {showOtherDescription && (
              <>
                <div className="col-12 my-2">
                  <label htmlFor="" className="mb-2">
                    Description of other event type
                  </label>
                  <textarea
                    name="eventType"
                    rows={5}
                    onChange={handleEvent}
                    className="form-control"
                    id=""
                  ></textarea>
                </div>
              </>
            )}
          </div>
          <p className="text-danger">{errors && errors?.eventType}</p>
        </div>
        {/* Start Date */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            Start Date
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            dateFormat="MM/d/yyyy"
            selected={event?.startDate}
            onChange={(date) => handleEvent({ target: { name: "startDate", value: date } })}
          />
          <p className="text-danger">{errors && errors?.date}</p>
        </div>
        {/* End Date */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="ticketCountInput" className="form-label fw-bold fs-6">
            End Date
          </label>
          <br />
          <DatePicker
            showIcon
            toggleCalendarOnIconClick
            className="form-control"
            dateFormat="MM/d/yyyy"
            selected={event?.startDate}
            onChange={(date) => handleEvent({ target: { name: "endDate", value: date } })}
          />
          <p className="text-danger">{errors && errors?.date}</p>
        </div>
        {/* Number of Shows */}
        <div className="col-12 my-2">
          <label htmlFor="" className="mb-2">
            Number of shows <span className="text-danger">*</span>
          </label>
          <input
            type="number"
            className="form-control"
            name="numberOfShows"
            value={event?.numberOfShows}
            onChange={handleEvent}
          />
          <p className="text-danger">{errors && errors?.numberOfShows}</p>
        </div>
        {/* Venue Location */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="" className="mb-2">
            Venue location (Postal area) <span className="text-danger">*</span>
          </label>
          <input
            name="venueLocation"
            value={event?.venueLocation}
            onChange={handleEvent}
            type="text"
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.venueLocation}</p>
        </div>
        {/* Venue Name */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="" className="mb-2">
            Venue name <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="venueName"
            value={event?.venueName}
            onChange={handleEvent}
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.venueName}</p>
        </div>
        {/* Artist */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="" className="mb-2">
            Artist <span className="text-danger">*</span>
          </label>
          <input
            name="artist"
            onChange={handleEvent}
            value={event?.artist}
            type="text"
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.artist}</p>
        </div>
        {/* Show Name */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="" className="mb-2">
            Show name
          </label>
          <input
            type="text"
            name="showName"
            value={event?.showName}
            onChange={handleEvent}
            className="form-control"
          />
        </div>
        {/* Audience */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="" className="mb-2">
            Audience
          </label>
          <input
            name="audience"
            value={event?.audience}
            onChange={handleEvent}
            type="number"
            className="form-control"
          />
        </div>
        {/* Gross Revenue from Ticket Sales */}
        <div className="col-md-6 col-12 my-2">
          <label htmlFor="" className="mb-2">
            Gross revenue from ticket sales
          </label>
          <input
            type="text"
            name="grossRevenue"
            value={event?.grossRevenue}
            onChange={handleEvent}
            className="form-control"
          />
        </div>
        {/* Notes */}
        <div className="col-12 my-2">
          <label htmlFor="" className="mb-2">
            Notes
          </label>
          <textarea
            name="notes"
            value={event?.notes}
            onChange={handleEvent}
            rows={5}
            className="form-control"
            id=""
          ></textarea>
        </div>
      </div>
    </div>
  );

  //Customer Information Form
  const CustomerInformation = () => (
    <div className="container">
      <div className="row">
        {/* Customer Name */}
        <div className="col-12">
          <h3 className="fw-bolder mt-5">Customer Details</h3>
          <div class="form-check">
            <p className="p-0 mb-1 fw-bolder">
              I am <span className="text-danger">*</span>
            </p>
            <input
              className="form-check-input"
              type="radio"
              name="user"
              value="Business"
              checked={event?.user === "Business"}
              id="flexRadioDefault1"
              onChange={handleEvent}
              onClick={() => setIsBusinessman(true)}
            />
            <label class="form-check-label" htmlFor="flexRadioDefault1">
              Business
            </label>
          </div>
          <div class="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="user"
              value="Person"
              checked={event?.user === "Person"}
              id="flexRadioDefault2"
              onChange={handleEvent}
              onClick={() => setIsBusinessman(false)}
            />
            <label class="form-check-label" htmlFor="flexRadioDefault2">
              Person
            </label>
          </div>
          <p className="text-danger">{errors && errors?.user}</p>
          <hr className="mt-3 mb-0" />
        </div>
        {/* BusinessMan and Person */}
        <div className="col-12 mb-3">
          {isBusinessman && (
            <>
              <div className="col-12 mb-3">
                <label htmlFor="" className="mb-2">
                  Organization number <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="organizationNumber"
                  value={event?.organizationNumber}
                  className="form-control"
                  onChange={handleEvent}
                />
                <p className="text-danger">
                  {errors && errors?.organizationNumber}
                </p>
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="" className="mb-2">
                  Name of Company <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="companyName"
                  value={event?.companyName}
                  onChange={handleEvent}
                  className="form-control"
                />
                <p className="text-danger">{errors && errors?.companyName}</p>
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="" className="mb-2">
                  Operating Company
                </label>
                <input
                  type="text"
                  name="operatingCompany"
                  value={event?.operatingCompany}
                  onChange={handleEvent}
                  className="form-control"
                />
              </div>
            </>
          )}
          <label htmlFor="" className="mb-2">
            Website
          </label>
          <input
            type="text"
            name="website"
            value={event?.website}
            onChange={handleEvent}
            className="form-control"
          />
        </div>
        <hr className="m-0 mb-1" />
        {/* Contact Person */}
        <div className="col-12">
          <h5 className="fw-bolder my-3">Contact Information</h5>
          <label htmlFor="" className="mb-2">
            Contact person <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="contactName"
            value={event?.contactName}
            onChange={handleEvent}
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.contactName}</p>
        </div>
        {/* Email */}
        <div className="col-12 my-2">
          <label htmlFor="" className="mb-2">
            E-mail <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="contactEmail"
            value={event?.contactEmail}
            onChange={handleEvent}
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.contactEmail}</p>
        </div>
        {/* Phone */}
        <div className="col-12 my-2">
          <label htmlFor="" className="mb-2">
            Phone <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="contactPhone"
            value={event?.contactPhone}
            onChange={handleEvent}
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.contactPhone}</p>
        </div>
        <hr className="my-3 mb-1" />
        {/* Invoice Address */}
        <div className="col-12">
          <h5 className="fw-bolder mb-3">Billing Address</h5>
          <label htmlFor="" className="my-2 m">
            Invoice Address (Street/road)
          </label>
          <input
            type="text"
            name="invoiceAddress"
            value={event?.invoiceAddress}
            onChange={handleEvent}
            className="form-control"
          />
        </div>
        {/* PO Box */}
        <div className="col-12 my-3">
          <label htmlFor="" className="mb-2">
            P.O. Box
          </label>
          <input
            type="text"
            name="invoicePoBox"
            value={event?.invoicePoBox || ""}
            onChange={handleEvent}
            className="form-control"
          />
        </div>
        {/* Postal code */}
        <div className="col-12">
          <label htmlFor="" className="mb-2">
            Postal code <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="invoicePostalCode"
            value={event?.invoicePostalCode || ""}
            onChange={handleEvent}
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.invoicePostalCode}</p>
        </div>
        {/* Postal Area */}
        <div className="col-12 my-3">
          <label htmlFor="" className="mb-2">
            Postal area <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            name="invoicePostalArea"
            value={event?.invoicePostalArea || ""}
            onChange={handleEvent}
            className="form-control"
          />
          <p className="text-danger">{errors && errors?.invoicePostalArea}</p>
        </div>
        {/* Invoice Notes */}
        <div className="col-12">
          <label htmlFor="" className="mb-2">
            Invoice notes
          </label>
          <input
            type="text"
            name="invoiceNote"
            value={event?.invoiceNote}
            onChange={handleEvent}
            className="form-control"
          />
        </div>
        {/* Invoice Deliver Option */}
        <div className="col-12 my-3">
          <div class="form-check">
            <p className="py-0 my-0 fw-bolder">
              Invoices delivered to <span className="text-danger">*</span>
            </p>
            <input
              class="form-check-input"
              type="radio"
              name="invoiceDeliver"
              value="EHF"
              checked={event?.invoiceDeliver === "EHF"}
              id="flexRadioDefault1"
              onChange={handleEvent}
            />
            <label class="form-check-label" for="flexRadioDefault1">
              EHF
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="invoiceDeliver"
              value="E-mail"
              id="flexRadioDefault2"
              onChange={handleEvent}
            />
            <label class="form-check-label" for="flexRadioDefault2">
              E-mail
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="invoiceDeliver"
              // value="Invoiceaddress"
              value={event?.invoiceAddress}
              checked={event?.invoiceDeliver === event?.invoiceAddress}
              // selected={event?.invoicedeliver === "Invoiceaddress"}
              id="flexRadioDefault2"
              onChange={handleEvent}
            />
            <label class="form-check-label" for="flexRadioDefault2">
              Invoice address
            </label>
          </div>
          {showInvoiceEmail && (
            <div className="col-12 my-3">
              <label htmlFor="" className="mb-2">
                Invoice e-mail <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="invoiceDeliver"
                onChange={handleEvent}
                className="form-control"
              />
            </div>
          )}
          <p className="text-danger">{errors && errors?.invoiceDeliver}</p>
        </div>
        <hr className="mb-1" />
        {/* Postal Address */}
        <div className="col-12">
          <h5 className="fw-bolder">Postal address</h5>
          <p>Postal address is equal billing address</p>
          <input
            type="checkbox"
            className="my-2 form-check-input"
            checked={postalAddress}
            selected={postalAddress}
            onChange={() => setPostalAddress(!postalAddress)}
            // onChange={handlePostalAddress}
            id="postalAddress"
          />
          {!postalAddress && (
            <>
              <div className="col-12">
                <p className="mb-2">Postal address (Street/road)</p>
                <input
                  type="text"
                  name="postalAddress"
                  value={event?.postalAddress}
                  onChange={handleEvent}
                  className="form-control mb-3"
                />
              </div>
              <div className="col-12">
                <p className="mb-2">P.O Box (Postal address)</p>
                <input
                  type="text"
                  name="postalPoBox"
                  value={event?.postalPoBox}
                  onChange={handleEvent}
                  className="form-control mb-3"
                />
              </div>
              <div className="col-12">
                <p className="mb-2">
                  ZIP code (Postal address){" "}
                  <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  name="postalZipCode"
                  value={event?.postalZipCode}
                  onChange={handleEvent}
                  className="form-control mb-3"
                />
                <p className="text-danger">{errors && errors?.postalZipCode}</p>
              </div>
              <div className="col-12">
                <p className="mb-2">
                  Postal area (Postal address)
                  <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  name="postalArea"
                  value={event?.postalArea}
                  onChange={handleEvent}
                  className="form-control mb-3"
                />
                <p className="text-danger">{errors && errors?.postalArea}</p>
              </div>
            </>
          )}
        </div>
        <hr className="mb-1" />
        {/* Office Address */}
        <div>
          <h5>Office Address</h5>
          <p>Office Address is equal billing address</p>
          <input
            type="checkbox"
            checked={officeAddress}
            onChange={() => setOfficeAddress(!officeAddress)}
            className="my-2 form-check-input"
            id="officeAddress"
          />
          {!officeAddress && (
            <>
              <div className="col-12">
                <p className="mb-2">Office address (Street/road)</p>
                <input
                  type="text"
                  name="officeAddress"
                  value={event?.officeAddress}
                  onChange={handleEvent}
                  className="form-control mb-3"
                />
                <p className="text-danger">{errors && errors?.officeAddress}</p>
              </div>
              <div className="col-12">
                <p>
                  Zip code (Office address )
                  <span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  name="officeZipCode"
                  value={event?.officeZipCode}
                  onChange={handleEvent}
                  className="form-control mb-3"
                />
                <p className="text-danger">{errors && errors?.officeZipCode}</p>
              </div>
              <div className="col-12">
                <p>
                  City (Office address )<span className="text-danger">*</span>
                </p>
                <input
                  type="text"
                  name="officeCity"
                  value={event?.officeCity}
                  onChange={handleEvent}
                  className="form-control mb-3"
                />
                <p className="text-danger">{errors && errors?.officeCity}</p>
              </div>
            </>
          )}
        </div>
        {/* Playlist */}
        <div>
          <h5 className="mb-1">Playlist</h5>
          <label htmlFor="">Word eller PDF dokument (maks 2 MB)</label>
          <label
            htmlFor="inputfile"
            className="btn border d-block col-1 my-4 bg-secondary text-white "
            style={{ minWidth: "fit-content" }}
          >
            Select File
          </label>
          <input
            type="file"
            id="inputfile"
            className="d-none"
            placeholder="Select File"
            onChange={handleFile}
          />
          {event?.file && (
            <div className="d-flex">
              <p className="my-2">{event?.file?.name}</p>
              <button
                onClick={handleDeleteFile}
                className="btn text-danger fw-bolder"
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // console.log("event ", event);
  // console.log("errors ", errors);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 my-3">
            <p className="fw-bolder">
              Fields marked with an <span className="text-danger">*</span> are
              required
            </p>
            <ul className="nav nav-pills d-flex " role="tablist">
              <li className="nav-item mx-2" role="presentation">
                <a
                  className="nav-link active border"
                  id="eventinformation"
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  aria-selected="false"
                  onClick={() => setIsCustomerPage(false)}
                >
                  Information About Your Event
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link border "
                  id="customerinformation"
                  data-bs-toggle="tab"
                  type="button"
                  role="tab"
                  onClick={() => setIsCustomerPage(true)}
                >
                  Customer details
                </a>
              </li>
            </ul>
            {iscustomerPage ? CustomerInformation() : EventInformation()}
          </div>
          <div>
            {iscustomerPage ? (
              <>
                <div className="d-flex flex-wrap">
                  <button
                    type="button"
                    className="btn btn-secondary my-3  mx-2 col-md-1 col-sm-2 w-auto"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={handleFormErrorCheck}
                  >
                    Submit Report To Tono
                  </button>
                  <button
                    className="btn btn-secondary my-3  mx-2 col-md-1 col-sm-2"
                    onClick={handleFormDownload}
                  >
                    Download
                  </button>

                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      name="customEmail"
                      className="form-check-input"
                      checked={showCustomEmail}
                      onClick={() => setShowCustomEmail(!showCustomEmail)}
                    />
                    <label htmlFor="" className="mx-2">
                      Send Report Email
                    </label>

                    {showCustomEmail && (
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          name="customEmail"
                          onChange={handleEvent}
                          placeholder="Enter Email Id"
                          className="form-control col-md-5"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-secondary my-3 mx-2 d-block"
                    onClick={handlePrevious}
                  >
                    Previous
                  </button>
                </div>
              </>
            ) : (
              <div>
                <button
                  className="btn btn-secondary my-3 d-block float-end"
                  onClick={handleNext}
                >
                  Next
                </button>
              </div>
            )}
          </div>
          <div className="mt-3">
            {errors !== "" && (
              <p className="text-danger">
                Please correct errors before submitting this form
              </p>
            )}
          </div>
        </div>
        {/* Model */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Tono Report
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                Your Tono Report cannot be generated again and is not editable.
                {errors && (
                  <p className="text-danger">
                    Note: Please Fill All Details Before Submit
                  </p>
                )}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  disabled={errors !== ""}
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleTonoFormSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tono;