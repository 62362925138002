import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { api, auth } from "services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { faCalendar, faClockFour } from "@fortawesome/free-regular-svg-icons";
import { currencyFormatter } from "utils";
import { useTranslation } from "react-i18next";
import NotFound from "./pages/NotFound";
import Spinner from "components/shared/Spinner";

const EventDetail = (props) => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [ticket, setTicket] = useState();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState([]);
  const [coupon, setCoupon] = useState({
    code: "",
    amount: 0,
    status: "",
    type: -1,
  });
  const MAX_TICKETS = 30;
  const MAX_COMPANION_TICKET = 1;
  const navigate = useNavigate();
  const { t } = useTranslation("eventdetails");

  //if cart is present then first time page loads manage these states
  useEffect(() => {
    let cart = JSON?.parse(localStorage?.getItem("bookingCart"));
    if (cart?.eventId !== id) {
      cart = null;
    }
    if (cart) {
      setTicket(cart?.ticketTypes);
      setCode(cart?.couponCode?.code);
      setCoupon(cart?.couponCode);
      setTotal(cart?.total);
      setDiscount(cart?.discount);
    }
  }, []);

  const getTime = () => {
    if (event) {
      let indiaTime = event?.get("date");
      let options = { timeZone: "Europe/Oslo", hour12: false };
      let norwayTime = indiaTime.toLocaleString("en-US", options);
      let datetime = norwayTime.split(",");
      let timeparts = datetime[1].split(":");
      let hr = timeparts[0];
      let mn = timeparts[1];
      let starttime = `${hr}:${mn}`;
      let endtime = `${hr}:${parseInt(mn) + 30}`;
      return { starttime: starttime, endtime: endtime };
    }
  };

  //method to create object with ticketTypes ex: { type1:0,type2:0...}
  const getTypesWithKeys = (obj) => {
    const result = {};
    for (const key in obj) {
      let index = obj[key].type;
      if (obj?.hasOwnProperty(key)) {
        result[`type${index}`] = 0;
      }
    }
    return result;
  }

  const fetchEventDetails = async () => {
    setLoading(true);
    try {
      const event = await api.getEventDetail(id);
      setEvent(event);
      const ticketTypes = event?.get("ticket_types");
      const ticketTypesObj = await getTypesWithKeys(ticketTypes);
      let cart = JSON.parse(localStorage.getItem("bookingCart"));
      if (cart) {
        setTicket(cart?.ticketTypes);
      } else {
        setTicket(ticketTypesObj);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventDetails();
  }, []);

  //coupon is removed then the coupon status set to default
  useEffect(() => {
    const cart = JSON.parse(localStorage?.getItem("bookingCart"));
    if (!cart) {
      if (code === "") {
        setDiscount(0);
        setCoupon({
          code: "",
          amount: 0,
          status: "",
          type: -1,
        });
      }
    }
  }, [code]);

  useEffect(() => {
    calculateDiscount();
  }, [ticket, coupon]);

  const updatePrice = (amount) => {
    if (total >= 0) {
      setTotal(total + parseInt(amount));
    } else {
      setTotal(total);
    }
  };

  const sumTypes = (obj) => {
    if (obj === null || typeof obj !== "object") {
      return 0;
    }
    return Object.values(obj).reduce((sum, value) => sum + value, 0);
  }

  const add = (ticketType, i) => {
    const totalTickets = sumTypes(ticket);
    let currentCount = ticket[`type${ticketType?.type}`];
    if (totalTickets < MAX_TICKETS) {
      //check the if any ticket type with value 0 it can add only once else it can add multiple times
      if (ticketType?.value == 0) {
        if (ticket[`type${ticketType?.type}`] < 1) {
          setTicket({
            ...ticket,
            [`type${ticketType?.type}`]: currentCount + 1,
          });
        }
      } else {
        setTicket({ ...ticket, [`type${ticketType?.type}`]: currentCount + 1 });
      }
      if (total >= 0) {
        updatePrice(ticketType?.value);
      }
    }
  };

  const reduce = (ticketType, i) => {
    let currentCount = ticket[`type${ticketType?.type}`];
    if (ticketType?.value == 0) {
      setTicket({ ...ticket, [`type${ticketType?.type}`]: currentCount - 1 });
    } else {
      setTicket({ ...ticket, [`type${ticketType?.type}`]: currentCount - 1 });
      if (total >= 0) {
        updatePrice(-ticketType?.value);
      } else {
        updatePrice(total);
      }
    }
  };

  const buy = async () => {
    const user = auth.fetchUser();
    const response = await api?.getEventDetail(id);
    const arrayofTickets = Object.values(ticket);
    const available_tickets = response?.get("max_tickets");
    let selected_number_of_tickets = sumTypes(ticket);

    if (user) {
      if (selected_number_of_tickets <= available_tickets) {
        try {
          let cart = localStorage.getItem("bookingCart");

          if (JSON.parse(cart)?.eventId === id) {
            localStorage.removeItem("bookingCart");
          }
          let amount = total - discount;
          let max_tickets_count = response.get("max_tickets");
          let sum = arrayofTickets.reduce((acc, cur) => acc + cur, 0);
          let total_tickets = max_tickets_count - sum;

          //creating new Booking
          const res = await api.createBooking(id, amount, coupon.code);
          let bookingId = res?.id;

          let booking_details = {
            eventId: id,
            totalTickets: total_tickets,
            ticketSoldOut: sum,
            ticketTypes: ticket,
            couponCode: coupon?.code,
            totalAmount: amount,
          };
          // storing the booking details for temporary
          let stringObj = JSON.stringify(booking_details);
          localStorage.setItem("booking", stringObj);

          if (user && bookingId) {
            const data = {
              email: user?.get("email"),
              amount: amount,
              bookingId: bookingId,
            };

            navigate("/checkout", {
              state: data,
            });
          }
        } catch (error) {
          console.log("error ", error);
        }
        setError("");
      } else {
        setError("Number of Tickets exceeds the available tickets");
      }
    } else {

      let obj = {
        eventId: id,
        ticketTypes: ticket,
        couponCode: coupon,
        total: total - discount ?? 0,
        discount: discount,
        code: code
      };
      localStorage.setItem("bookingCart", JSON.stringify({
        eventId: id,
        ticketTypes: ticket,
        couponCode: coupon,
        total: total,
        discount: discount,
        code: code,
      })
      );
      navigate("/login");
    }
  };

  const RenderTicketTypes = (props) => {
    const tickets = props.tickets;
    var types = Object.keys(tickets);
    return (
      <ul className="">
        {types.map((type, j) => {
          var keys = Object.keys(ticket);
          let key = tickets[type]?.type;
          return (
            <li key={key}>
              <div className="row">
                <div className="col-md-6 col-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6">
                  <span className="fs-4">
                    {type} {tickets[type]?.type}
                  </span>
                  <br />
                  <span className="fs-6">( {tickets[type]?.value} NOK )</span>
                </div>
                <div
                  className="col-md-6 col-6 col-sm-6 col-xs-6 col-lg-6 col-xl-6"
                  align="right"
                >
                  <button
                    class="btn btn-outline-light btn-sm"
                    onClick={() => reduce(tickets[type], key)}
                    disabled={ticket[keys[j]] == 0}
                  >
                    -
                  </button>
                  <span class="mx-4">{ticket[`type${key}`]}</span>
                  <button
                    class="btn btn-outline-light btn-sm"
                    onClick={() => add(tickets[type], key)}
                    disabled={ticket[keys[j]] == MAX_TICKETS}
                  >
                    +
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  //method to check the ticketType from current event based on ticketType should match the value must be zero
  const getObjectByTypeAndValueZero = (ticketTypeNumber, ticketTypesObject) => {
    if (ticketTypesObject) {
      const result = Object.values(ticketTypesObject)?.filter(
        (obj) => obj?.value == 0
      );
      return result.length > 0 ? true : false;
    }
  };

  //apply the coupon
  const applyCoupon = () => {
    if (total > 0) {
      api.getCouponByCode(code, id).then((result) => {
        const type = result?.get("type");
        if (result != null) {
          setCoupon({
            code: code,
            amount: result?.get("amount"),
            status: "You saved ",
            type: result?.get("type"),
          });
        } else {
          setCoupon({
            code: "",
            amount: 0,
            status: "Coupon Code Invalid",
            type: -1,
          });
        }
      });
    } else {
      //If no tickets selected then coupon code shouldn't be applied.
      setCoupon({
        code: "",
        amount: 0,
        status: "Please select Tickets first",
        type: -1,
      });
      setCode("");
    }
  };

  const calculateDiscount = () => {
    let discount = 0;
    const isTypeZeroExist = coupon?.type == 0 ? true : false;
    const ticketTypes = event?.get("ticket_types");
    const isTypeValueZero = getObjectByTypeAndValueZero(coupon.type, ticketTypes);
    const sum = sumTypes(ticket);
    console.log("sum ",sum)
    if (sum > 0) {
      if (coupon?.type === 0) {
        discount = isTypeZeroExist && isTypeValueZero ? (sum - 1) * (coupon?.amount) : sum * (coupon?.amount);
      }
      else {
        discount = ticket[`type${coupon.type}`] * coupon.amount || 0; // Default to 0 if the type doesn't exist
      }
    }
    setDiscount(discount);
  };

  // console.log("ticket ", ticket);
  // console.log("total ", total);
  // console.log("Coupon ", coupon);
  // console.log("isTypeValueZero ", isTypeValueZero);
  // console.log("discount ", discount);

  if (loading) {
    return <Spinner />;
  }

  if (!event) return <NotFound />;
  return (
    <div className="container mb-5">
      <div class="row pb-5">
        <div class="col-md-10 col-12 offset-md-1 text-center" align="center">
          <img
            src={event?.get("cover_photo")}
            className="img-fluid w-100"
            style={{ aspectRatio: "16/9", objectFit: "fill" }}
          />
          <br />
          <br />
          <h1>{event?.get("title")}</h1>
        </div>
      </div>
      <div class="row my-3">
        <div class="col-md-6">
          <FontAwesomeIcon icon={faCalendar} className="fs-5 mx-2" />
          <i>
            <b>{t("date")}</b>
            {event?.get("date")?.toDateString()}
          </i>
          <br />
          <FontAwesomeIcon icon={faClockFour} className="fs-5 mx-2" />
          <b>{t("time")}</b>
          {getTime()?.starttime}
          <br />
          <div className="d-flex">
            <div style={{ minWidth: "fit-content" }}>
              <FontAwesomeIcon icon={faLocationDot} className="fs-5 mx-2" />
              <b>{t("address")}</b>
            </div>
            <p> {event.get("address")}</p>
          </div>
          <br />

          <p></p>
          <span className="pre-wrap">{event?.get("description")}</span>
        </div>
        <br />
        <div class="col-md-6" align="left">
          <div class="bg-secondary p-3 p-md-4 text-white">
            <h3>
              <p>{t("maxTicket")}</p>
            </h3>
            <p>{t("description")}</p>

            <RenderTicketTypes tickets={event?.get("ticket_types")} />
            <h4 className="d-grid">
              <br />
              <div class="input-group mt-3">
                <input
                  type="text"
                  value={code}
                  class="form-control bg-secondary text-white"
                  placeholder={t("code")}
                  aria-label="discount"
                  aria-describedby="button-discount"
                  disabled={total == 0 ? true : false}
                  onChange={(e) => {
                    if (e.target.value == "") {
                      setDiscount(0);
                    }
                    setCode(e.target.value);
                    setCoupon({ ...coupon, status: "" });
                  }}
                ></input>
                <button
                  class="btn btn-light"
                  type="button"
                  id="button-discount"
                  onClick={applyCoupon}
                  disabled={code == "" ? true : false}
                >
                  {t("couponCodebtn")}
                </button>
              </div>
              <div className="pb-3 pt-1 small text-end" align="left">
                <i>
                  <small>
                    {discount > 0
                      ? coupon.status +
                      currencyFormatter(total > 0 ? discount : 0)
                      : coupon.status}
                  </small>
                </i>
              </div>
              <pre
                className="text-decoration-none m-0"
                style={{ fontFamily: "sans-serif" }}
              >
                {t("total")} : {currencyFormatter(total - discount <= 0 ? 0 : total - discount)}
              </pre>

              <p className="fs-5 my-3 fst-italic">{error && error}</p>

              <button
                className="btn btn-outline-light btn-block btn-lg mt-3"
                onClick={buy}
                disabled={total > 0 ? false : true}
              >
                {t("buynow")}
              </button>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventDetail;